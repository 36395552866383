<template>
  <div class="container min-vh-80">
    <h2 class="p-2 text-center">Contact Us</h2>

    <p class="pt-3">
      Want to send us some love? See something broken, or have ideas for how we could improve the experience on reagent.travel?<br />
      Drop us a line at:
      <a href="mailto:hello@reagent.travel">hello@reagent.travel</a>
    </p>
  </div>
  <Footer />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Footer from '@/components/Footer.vue'

export default defineComponent({
  name: 'ContactUsPage',
  components: {
    Footer
  }
})
</script>

<style scoped>
</style>
