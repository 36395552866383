
import { defineComponent } from 'vue'
import { mapGetters, mapMutations } from 'vuex'
import ItineraryRow from '@/components/ItineraryRow.vue'

export default defineComponent({
  name: 'FavoritesModal',
  components: {
    ItineraryRow
  },
  methods: {
    ...mapMutations(['toggleFavoritesModal']),
    ...mapGetters(['favoriteItineraries', 'returnPresent'])
  }
})

