<template>
  <svg preserveAspectRatio="none" viewBox="0 0 1000 500">
    <path stroke="black" stroke-width="20" fill="none" marker-end="url(#arrow)" d="M 0, 500 Q 500, -300, 1000, 500"></path>
  </svg>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from 'vue'

export default defineComponent({
  name: 'FlightArrow'
})
</script>

<style scoped>

svg {
  width: 100%;
  height: 20px;
}

</style>
