import { InjectionKey } from 'vue'
import { createStore, useStore as baseUseStore, Store } from 'vuex'
import ItineraryTree from '@/types/ItineraryTree'
import _ from 'lodash'
import Itinerary from '@/types/Itinerary'

export interface ItineraryTreeState {
  itineraryTrees: ItineraryTree[],
  itineraries: Itinerary[],
  oItineraries: Itinerary[],
  airlineCodes: string[],
  selectedAirlineCodes: string[],
  excludedAirlineCodes: string[],
  featureMinMaxes: any,
  showFavoritesModal: boolean,
  showAirlinesModal: boolean,
  showSpinner: boolean,
  showNoItineraries: boolean,
  apiLoadIncrement: number,
  timeoutHandle: any
}

export const key: InjectionKey<Store<ItineraryTreeState>> = Symbol('store')

export const store = createStore<ItineraryTreeState>({
  state: {
    itineraryTrees: [],
    itineraries: [],
    oItineraries: [],
    airlineCodes: [],
    featureMinMaxes: {},
    showFavoritesModal: false,
    showAirlinesModal: false,
    selectedAirlineCodes: [],
    excludedAirlineCodes: [],
    showSpinner: false,
    showNoItineraries: false,
    apiLoadIncrement: 0,
    timeoutHandle: null
  },
  mutations: {
    updateItineraryTrees (state, newTrees) {
      state.itineraryTrees.length = 0
      state.itineraryTrees.push(...newTrees)
    },
    updateItineraries (state, newItineraries) {
      state.itineraries.length = 0
      state.itineraries.push(...newItineraries)
    },
    updateOItineraries (state, newOItineraries) {
      state.oItineraries.length = 0
      state.oItineraries.push(...newOItineraries)
    },
    updateAirlineCodes (state, newAirlineCodes) {
      state.airlineCodes.length = 0
      state.airlineCodes.push(...newAirlineCodes)
    },
    updateSelectedAirlineCodes (state, selectedAirlineCodes) {
      state.selectedAirlineCodes.length = 0
      state.selectedAirlineCodes.push(...selectedAirlineCodes)
    },
    updateExcludedAirlineCodes (state, excludedAirlineCodes) {
      state.excludedAirlineCodes.length = 0
      state.excludedAirlineCodes.push(...excludedAirlineCodes)
    },
    updateFeatureMinMaxes (state, newMinMaxes) {
      Object.assign(state.featureMinMaxes, newMinMaxes)
    },
    toggleNextLevel (state, id) {
      const itineraryTree = findItineraryTreeInTrees(state.itineraryTrees, id)
      if (itineraryTree) { itineraryTree.showNextLevel = !itineraryTree.showNextLevel }
    },
    toggleFavourite (state, id) {
      const itinerary = _.find(state.itineraries, i => i.id === id)
      if (itinerary) { itinerary.favourite = !itinerary.favourite }
    },
    toggleFavoritesModal (state) {
      state.showFavoritesModal = !state.showFavoritesModal
    },
    toggleAirlinesModal (state) {
      state.showAirlinesModal = !state.showAirlinesModal
    },
    showSpinner (state) {
      state.showSpinner = true
    },
    hideSpinner (state) {
      state.showSpinner = false
    },
    showNoItineraries (state) {
      state.itineraryTrees.length = 0
      state.itineraries.length = 0
      state.showNoItineraries = true
    },
    hideNoItineraries (state) {
      state.showNoItineraries = false
    },
    incrementApiLoad (state) {
      state.apiLoadIncrement += 1
    },
    clearTimeout (state) {
      if (state.timeoutHandle) { clearTimeout(state.timeoutHandle) }
    },
    setupTimeout (state) {
      clearTimeout()
      state.timeoutHandle = setTimeout(() => {
        alert('Sorry your search results have timed out, please search again.')
        state.itineraryTrees.length = 0
        state.itineraries.length = 0
        state.oItineraries.length = 0
        state.showNoItineraries = false
      }, 1000 * 60 * 30)
    },
    clearItineraries (state) {
      // Duplicated in set timeout above!
      state.itineraryTrees.length = 0
      state.itineraries.length = 0
      state.oItineraries.length = 0
      state.showNoItineraries = false
      state.apiLoadIncrement += 1
    }
  },
  getters: {
    favoriteItineraries (state) {
      return state.itineraries.filter(i => i.favourite)
    },
    returnPresent (state) {
      return !!state.itineraries[0]?.returnTrip
    },
    itinerariesPresent (state) {
      return !!state.itineraryTrees[0]
    },
    oItinerariesPresent (state) {
      return !!state.oItineraries[0]
    },
    getItinerary: (state) => (id : string) => {
      return _.find(state.itineraries, i => i.id === id)
    },
    getSelectedAirlines (state) {
      return state.selectedAirlineCodes
    },
    getExcludedAirlines (state) {
      return state.excludedAirlineCodes
    },
    getItineraryTreesItineraryCount (state) {
      return countItinerariesFromTrees(state.itineraryTrees)
    }
  }
})

export function useStore () {
  return baseUseStore(key)
}

export function findItineraryTreeInTrees (trees : ItineraryTree[], id: number) : (ItineraryTree | null) {
  for (let i = 0; i < trees.length; i++) {
    const result = findItineraryTree(trees[i], id)
    if (result) {
      return result
    }
  }
  return null
}

export function findItineraryTree (tree : ItineraryTree, id: number): ItineraryTree | null {
  if (tree.id === id) {
    return tree
  } else {
    for (let i = 0; i < tree.nextLevelItineraryTrees.length; i++) {
      const result = findItineraryTree(tree.nextLevelItineraryTrees[i], id)
      if (result) {
        return result
      }
    }
  }
  return null
}

export function countItinerariesFromTrees (trees : ItineraryTree[]): number {
  let count = 0
  for (let i = 0; i < trees.length; i++) {
    count += 1
    count += countItinerariesFromTrees(trees[i].nextLevelItineraryTrees)
  }
  return count
}
