
import { defineComponent } from 'vue'
import Footer from '@/components/Footer.vue'
import { mapMutations } from 'vuex'

export default defineComponent({
  name: 'EmailSignupPage',
  components: {
    Footer
  },
  data () {
    return {
      email: '',
      successMessage: '',
      errorMessage: ''
    }
  },
  methods: {
    submitEmail () {
      this.errorMessage = ''
      this.successMessage = ''

      this.axios.post(process.env.VUE_APP_API_BASE_PATH + '/email-signup-create',
        { email: this.email }).then(response => {
        this.successMessage = 'Email Submitted Successfully'
      }).catch(() => {
        this.errorMessage = 'There was an error submitting your email, please try again.'
      })
    }
  }
})
