
import { computed, defineComponent, ref } from 'vue'
import { store, useStore } from '@/store'
import _round from 'lodash/round'
import { mapGetters, mapMutations } from 'vuex'
import TopForm from '@/components/TopForm.vue'
import Footer from '@/components/Footer.vue'
import AllItineraries from '@/components/AllItineraries.vue'
import ItineraryStructure from '@/components/ItineraryStructure.vue'
import ItineraryFooter from '@/components/ItineraryFooter.vue'
import Bugsnag from '@bugsnag/js'
import app from '@/App.vue'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'DemoPage',
  components: { Footer },
  methods: {
    setTraveller (travellerType : string) {
      // eslint-disable-next-line no-undef
      Botcopy.setCXParameters({
        payload: {
          traveller_type: { stringValue: travellerType }
        }
      })
    }
  }
})
