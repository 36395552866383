<template>
  <Popper hover placement="top">
    <img :src="'https://images.kiwi.com/airlines/32/' + flight.airlineCode + '.png'" width="18"/>
    <template #content>
      <img :src="'https://images.kiwi.com/airlines/32/' + flight.airlineCode + '.png'" width="18"/>
      <strong>&nbsp;
        {{ airlineNames[flight.airlineCode] }}
      </strong>
      <br />
      Flight No: <strong>{{ flight.airlineCode }} {{ flight.flightNumber }}</strong>
      <br />
      Duration: <strong>{{ formatDuration(flight.duration) }}</strong>
    </template>
  </Popper>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from 'vue'
import airlineNames from './../assets/airlines.json'
import Flight from '@/types/Flight'
import DurationHelperMixin from '@/helpers/DurationHelpers'

export default defineComponent({
  name: 'TripBoxFlightInfo',
  mixins: [DurationHelperMixin],
  props: {
    flight: { type: Object as PropType<Flight>, required: true }
  },
  setup () {
    return { airlineNames }
  }
})
</script>

<style scoped>
</style>
