
import { defineComponent } from 'vue'
import { mapMutations } from 'vuex'

export default defineComponent({
  name: 'Header',
  emits: ['resetForm'],
  methods: {
    ...mapMutations(['clearItineraries', 'incrementResetFormInteger']),
    resetSearch () {
      this.$router.push({ path: 'search' })
      this.clearItineraries()
      this.$emit('resetForm')
    }
  }
})
