<template>
  <div class="modal modal-active" tabindex="-1">
    <div class="modal-dialog modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title w-100 text-center">
            Select Airlines
          </h5>
          <button @click="toggleAirlinesModal()" type="button" class="btn-close" />
        </div>
        <div class="modal-body">
          <div class="container">
            <div class="row">
              <div class="col-3" v-for="(alliance_airline_codes, alliance_name) in allianceCodes" :key="alliance_name">
                <h3>{{ alliance_name }}</h3>
                <span @click="selectAll(alliance_name)" class="all-buttons">Select All</span>
                |
                <span @click="deselectAll(alliance_name)" class="all-buttons">Deselect All</span>
                <div v-for="airlineCode in allAirlinesCodes.filter(ac => alliance_airline_codes.includes(ac))" :key="airlineCode" class="d-flex align-items-center">
                  <input type="checkbox" :id="'airline-' + airlineCode" :value="airlineCode" v-model="selectedAirlines" @change="updateAirlinesInStore" class="p-1" style="height: 20px">
                  <label :for="'airline-' + airlineCode" class="p-1">
                    <img :src="'https://images.kiwi.com/airlines/32/' + airlineCode + '.png'" height="15" class="mx-1"/>
                    {{ airlineNames[airlineCode] }}
                  </label>
                </div>
              </div>
              <div class="col-3">
                <h3>No Alliance</h3>
                <div v-for="airlineCode in allAirlinesCodes.filter(ac => !allianceAnyCode.includes(ac))" :key="airlineCode" class="d-flex align-items-center">
                  <input type="checkbox" :id="'airline-' + airlineCode" :value="airlineCode" v-model="selectedAirlines" @change="updateAirlinesInStore" class="p-1">
                  <label :for="'airline-' + airlineCode" class="p-1">
                    <img :src="'https://images.kiwi.com/airlines/32/' + airlineCode + '.png'" width="15" class="mx-1"/>
                    {{ airlineNames[airlineCode] }}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" @click="updateAirlinesClicked">Update Airlines</button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-backdrop show"></div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue'
import { useStore } from '@/store'
import { mapGetters, mapMutations } from 'vuex'
import airlineNames from './../assets/airlines.json'
import _ from 'lodash'

export default defineComponent({
  name: 'AirlineSelector',
  emits: ['searchWithUpdatedAirlines'],
  setup () {
    const store = useStore()
    const allAirlinesCodes = ref(_.sortBy(_.uniq(store.state.airlineCodes.concat(store.state.excludedAirlineCodes))))
    const airlineCodesFromAPI = ref(store.state.airlineCodes)
    const allianceCodes : {[index: string]: string[]} = {
      'Star Alliance': ['A3', 'AC', 'CA', 'AI', 'NZ', 'NH', 'OZ', 'OS', 'AV', 'SN', 'CM', 'OU', 'MS', 'BR', 'ET', 'LO', 'LH', 'SK', 'ZH', 'SQ', 'SA', 'LX', 'TP', 'TG', 'TK', 'UA'],
      SkyTeam: ['SU', 'AR', 'AM', 'UX', 'AF', 'AZ', 'CI', 'MU', 'OK', 'DL', 'KQ', 'KL', 'KE', 'ME', 'SV', 'RO', 'VN', 'MF', 'GA'],
      OneWorld: ['AA', 'BA', 'CX', 'AY', 'IB', 'JL', 'MH', 'QF', 'QR', 'RJ', 'S7', 'UL', 'AT']
    }
    const allianceAnyCode = _.flatten(_.values(allianceCodes))
    return { allAirlinesCodes, airlineCodesFromAPI, allianceCodes, allianceAnyCode, airlineNames }
  },
  data () {
    return {
      selectedAirlines: [] as string[]
    }
  },
  mounted () {
    this.selectedAirlines = _.difference(this.airlineCodesFromAPI, this.getExcludedAirlines())
  },
  computed: {
    excludedAirlines () : string[] {
      return _.difference(this.allAirlinesCodes, this.selectedAirlines)
    }
  },
  methods: {
    ...mapMutations(['toggleAirlinesModal', 'updateSelectedAirlineCodes', 'updateExcludedAirlineCodes']),
    ...mapGetters(['returnPresent', 'getExcludedAirlines']),
    updateAirlinesInStore () {
      this.updateSelectedAirlineCodes(this.selectedAirlines)
      this.updateExcludedAirlineCodes(this.excludedAirlines)
    },
    updateAirlinesClicked () {
      this.$emit('searchWithUpdatedAirlines')
      this.toggleAirlinesModal()
    },
    selectAll (allianceName : string) {
      this.selectedAirlines = _.uniq(_.concat(this.selectedAirlines, this.allianceCodes[allianceName]))
      this.updateAirlinesInStore()
    },
    deselectAll (allianceName : string) {
      this.selectedAirlines = _.difference(this.selectedAirlines, this.allianceCodes[allianceName])
      this.updateAirlinesInStore()
    }
  }
})
</script>

<style scoped>

.modal-active{
  display:block;
}

.modal-dialog{
  position: relative;
  display: table; /* This is important */
  overflow-y: auto;
  overflow-x: auto;
  width: auto;
  min-width: 1000px;
}

.all-buttons {
  font-size: 12px;
  text-decoration: underline;
  cursor: pointer;
}

</style>
