<template>
  <div class="row pt-1 d-flex align-items-end text-center" v-if="itineraryTree.level === 1" style="padding-left: 15px" >
    <div class="col-2"></div>
    <div class="heading col">Price <span v-if="itineraryTree.primaryFeature === 'Price'" style="font-size: 8px">(USD)</span></div>

    <div class="heading col d-flex justify-content-center">
      <div>
        Duration
      </div>
      <Popper v-if="itineraryTree.level === 1 && itineraryTree.primaryFeature === 'Price' && returnPresent()" hover placement="top" style="border-width: 0px; margin: 0px 0px 0px 3px">
        <font-awesome-icon icon="info-circle" class="text-gray" />
        <template #content>
          This is the average duration for both directions.
        </template>
      </Popper>
    </div>
    <div class="heading col d-flex justify-content-center">
      <div>
        Stops
      </div>
      <Popper v-if="itineraryTree.level === 1 && itineraryTree.primaryFeature === 'Price' && returnPresent()" hover placement="top" style="border-width: 0px; margin: 0px 0px 0px 3px;">
        <font-awesome-icon icon="info-circle" class="text-gray" />
        <template #content>
          This is the total number of stops for both directions.
        </template>
      </Popper>
    </div>
    <div class="heading col-3 px-2">Outbound Times</div>
    <div class="heading col-3 px-2" v-if="returnPresent()">Return Times</div>
    <div class="heading col d-flex justify-content-center">
      <Popper hover placement="top" v-if="itineraryTree.level === 1 && itineraryTree.primaryFeature === 'Price'">
        <font-awesome-icon icon="info-circle" class="text-gray" />
        <template #content>
          Results provided by <a href="https://www.kiwi.com/">Kiwi.com</a>
        </template>
      </Popper>
    </div>
  </div>
  <div class="large-container rounded border-2 bg-white" :id="itineraryTree.primaryFeature.replace(/\s/gi, '-') + '-row'" v-bind:class="[itineraryTree.level === 1 ? 'container border' : '']">
    <ItineraryRow :itineraryTree="itineraryTree" :showDescriptionRow="true" :key="itineraryTree.itineraryID" />
    <div v-if="itineraryTree.showNextLevel">
      <div v-for="itineraryTree in itineraryTree.nextLevelItineraryTrees" v-bind:key="itineraryTree.id">
        <ItineraryStructure :itineraryTree="itineraryTree" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import ItineraryTree from '@/types/ItineraryTree'
import ItineraryRow from '@/components/ItineraryRow.vue'
import { mapGetters } from 'vuex'

export default defineComponent({
  name: 'ItineraryStructure',
  components: {
    ItineraryRow
  },
  props: {
    itineraryTree: { type: Object as PropType<ItineraryTree>, required: true }
  },
  methods: {
    ...mapGetters(['returnPresent'])
  }
})
</script>

<style scoped>
.heading {
  font-size: 12px;
}

.large-container {
  border-color: darkgray !important;
}

</style>
