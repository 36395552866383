
import { computed, defineComponent, ref } from 'vue'
import { store, useStore } from '@/store'
import _round from 'lodash/round'
import { mapGetters, mapMutations } from 'vuex'
import TopForm from '@/components/TopForm.vue'
import Footer from '@/components/Footer.vue'
import AllItineraries from '@/components/AllItineraries.vue'
import ItineraryStructure from '@/components/ItineraryStructure.vue'
import ItineraryFooter from '@/components/ItineraryFooter.vue'
import Bugsnag from '@bugsnag/js'

export default defineComponent({
  name: 'ItineraryPage',
  components: {
    ItineraryFooter,
    TopForm,
    ItineraryStructure,
    AllItineraries,
    Footer
  },
  setup () {
    const store = useStore()

    const itineraryTrees = computed(() => store.state.itineraryTrees)
    const allItineraries = computed(() => store.state.itineraries)
    const allOItineraries = computed(() => store.state.oItineraries)
    const showSpinner = computed(() => store.state.showSpinner)
    const showNoItineraries = computed(() => store.state.showNoItineraries)
    const apiLoadIncrement = computed(() => store.state.apiLoadIncrement)

    return { itineraryTrees, allItineraries, showSpinner, showNoItineraries, apiLoadIncrement, allOItineraries }
  },
  watch: {
    apiLoadIncrement () {
      if (this.itinerariesPresent() && localStorage.getItem('intro-shown') !== 'true') {
        (this.$refs.tour as any).start()
        localStorage.setItem('intro-shown', 'true')
      }
    }
  },
  methods: {
    ...mapGetters(['allItineraries', 'itinerariesPresent', 'oItinerariesPresent', 'getItineraryTreesItineraryCount']),
    resetForm () {
      (this.$refs.topForm as typeof TopForm).resetForm()
    },
    introSteps () {
      const step1 = {
        header: {
          title: 'Our results are a little different!'
        },
        content: 'We show flights based on what you care about. Click next to get a feel for it.'
      }

      const step2 = {
        target: '#Price-row',
        header: {
          title: 'Price Conscious?'
        },
        content: 'This is the flight best optimized for price, while also considering everything else.',
        params: {
          placement: 'bottom'
        }
      }

      const step2expand = {
        target: '#Price-expand-button',
        header: {
          title: 'Expand to see similar flights'
        },
        content: 'See similar options that are further optimized for price - at the expense of other things.',
        params: {
          placement: 'bottom'
        }
      }

      const step3 = {
        target: '#Travel-Duration-row',
        header: {
          title: 'Duration more important than price?'
        },
        content: 'This section optimizes for travel time instead of price. It can also be expanded just like the one above.',
        params: {
          placement: 'bottom'
        }
      }

      const step3expand = {
        target: '#Travel-Duration-expand-button',
        header: {
          title: 'Expand to see similar flights'
        },
        content: 'See similar options that are further optimized for duration - at the expense of other things.',
        params: {
          placement: 'bottom'
        }
      }

      const step4 = {
        header: {
          title: 'Enjoy!'
        },
        content: 'There\'s a lot more to play with. Have fun searching smarter with Reagent!'
      }

      const steps = [step1, step2, step3, step4]

      if (this.itineraryTrees.length) {
        if (this.itineraryTrees[0].nextLevelItineraryTrees.length) {
          steps.splice(2, 0, step2expand)
        } else if (this.itineraryTrees[1].nextLevelItineraryTrees.length) {
          steps.splice(3, 0, step3expand)
        }
      }

      return steps
    }
  }
})
