
import { computed, defineComponent } from 'vue'
import { useStore } from '@/store'
import { mapGetters, mapMutations } from 'vuex'
import FavoritesModal from '@/components/FavoritesModal.vue'
import MinValues from '@/components/MinValues.vue'

export default defineComponent({
  name: 'ItineraryFooter',
  components: {
    FavoritesModal,
    MinValues
  },
  setup () {
    const store = useStore()

    const showFavouritesModal = computed(() => store.state.showFavoritesModal)
    return { showFavouritesModal }
  },
  methods: {
    ...mapMutations(['toggleFavoritesModal']),
    ...mapGetters(['favoriteItineraries', 'itinerariesPresent', 'returnPresent'])
  }
})
