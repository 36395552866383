<template>
  <div class="col-2 text-start"><strong>For Reference</strong></div>
  <div class="heading col dash text-center">
    <div style="font-size: 10px">Cheapest</div>
    <div>${{ featureMinMaxes['min']['Price'] }}</div>
  </div>
  <div class="heading col dash text-center">
    <div style="font-size: 10px">Fastest</div>
    <div>
      {{ durationDisplay(featureMinMaxes['min']['Travel Duration']) }}
    </div>
  </div>
  <div class="heading col dash text-center" style="border-right: gray dashed 1px">
    <div style="font-size: 10px">Least Stops</div>
    <div>
      {{ featureMinMaxes['min']['Stopovers'] }} stops
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue'
import { useStore } from '@/store'
import _round from 'lodash/round'
import { mapGetters, mapMutations } from 'vuex'

export default defineComponent({
  name: 'MinValues',
  setup () {
    const store = useStore()
    const featureMinMaxes = ref(store.state.featureMinMaxes)
    return { featureMinMaxes }
  },
  methods: {
    ...mapGetters(['returnPresent']),
    durationDisplay (durationInSeconds : number) {
      if (this.returnPresent()) durationInSeconds /= 2
      durationInSeconds /= 60
      return `${_round(Math.floor(durationInSeconds / 60.0))}h ${_round(durationInSeconds % 60.0)}m`
    }
  }
})
</script>

<style scoped>
.heading {
  font-size: 15px;
}

.dash {
  border-left: gray dashed 1px;
}
</style>
