
import { defineComponent, PropType, ref } from 'vue'
import airportNames from './../assets/airports.json'
import Flight from '@/types/Flight'
import DurationHelperMixin from '@/helpers/DurationHelpers'

export default defineComponent({
  name: 'TripBoxStopInfo',
  mixins: [DurationHelperMixin],
  props: {
    bold: { type: Boolean, required: true },
    flight: { type: Object as PropType<Flight>, required: true }
  },
  setup () {
    return { airportNames }
  }
})
