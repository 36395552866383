import dayjs from 'dayjs'

export default {
  methods: {
    formatDate (dateParam : Date) {
      const date = dayjs(dateParam)
      return date.format('h:mma')
    },
    formatDateWithDay (dateParam : Date) {
      const date = dayjs(dateParam)
      return date.format('ddd, MMM D YYYY, h:mma')
    }
  }
}
