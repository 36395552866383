
import { ref, defineComponent, PropType } from 'vue'
import { useI18n } from 'vue-i18n'
import ItineraryTree from '@/types/ItineraryTree'
import { mapGetters, mapMutations } from 'vuex'
import TripBox from '@/components/TripBox.vue'
import Itinerary from '@/types/Itinerary'
import { useStore } from '@/store'
import DurationHelperMixin from '@/helpers/DurationHelpers'
import _ from 'lodash'

export default defineComponent({
  name: 'ItineraryRow',
  props: {
    itineraryTree: { type: Object as PropType<ItineraryTree>, required: false },
    plainItinerary: { type: Object as PropType<Itinerary>, required: false },
    showDescriptionRow: { type: Boolean, required: true },
    showFavouriteRemove: { type: Boolean, required: false }
  },
  components: {
    TripBox
  },
  mixins: [DurationHelperMixin],
  setup (props) {
    const { t } = useI18n({
      locale: 'en',
      messages: {
      }
    })

    const store = useStore()
    const itinerary = ((props.itineraryTree) ? ref(store.getters.getItinerary(props.itineraryTree.itineraryID) as Itinerary)
      : ref(props.plainItinerary as Itinerary))
    const level = props?.itineraryTree?.level ?? 0

    let metaData = ''
    if (!_.isEmpty(props.itineraryTree?.metadata)) {
      metaData += 'Avg: ' + props.itineraryTree?.metadata?.percentage_average + '% '
      metaData += ' --- '
      _.forOwn(props.itineraryTree?.metadata?.importances, (v, k) => { metaData += k + ': ' + v + ', ' })
    }

    return { t, itinerary, level, metaData }
  },
  computed: {
    primaryFeature () : string {
      return this.itineraryTree?.primaryFeature || ''
    }
  },
  methods: {
    ...mapMutations(['toggleNextLevel', 'toggleFavourite']),
    ...mapGetters(['getItinerary', 'returnPresent']),

    seeMoreOptionsText () {
      if (this.$props.plainItinerary) return ''
      if (!this.$props.itineraryTree) return ''

      const seeMoreText : any = {
        Price: 'similar options',
        'Travel Duration': 'similar options',
        Stopovers: 'options with less stops',
        'Outbound Takeoff Time Around': 'options closer to preferred times',
        'Outbound Landing Time Around': 'options closer to preferred times',
        'Return Takeoff Time Around': 'options closer to preferred times',
        'Return Landing Time Around': 'options closer to preferred times',
        'Outbound & Return Times': 'options closer to preferred times'
      }

      const prefix = this.$props.itineraryTree.showNextLevel ? 'Hide' : 'See'
      return prefix + ' ' + 'similar options' // seeMoreText[this.$props.itineraryTree.primaryFeature]
    },
    rowDescription () {
      if (this.$props.plainItinerary) return ''

      const featureDescriptionLevels : any = {
        1: {
          Price: 'Value for Price',
          'Travel Duration': 'Value for Duration',
          Stopovers: 'Value for Stops',
          'Outbound Takeoff Time Around': 'Closeness to preferred time',
          'Outbound Landing Time Around': 'Closeness to preferred time',
          'Return Takeoff Time Around': 'Closeness to preferred time',
          'Return Landing Time Around': 'Closeness to preferred time',
          'Outbound & Return Times': 'Closeness to preferred times'
        },
        2: {
          Price: 'Cheaper',
          'Travel Duration': 'Faster',
          Stopovers: 'Less Stops',
          'Outbound Takeoff Time Around': 'Closer to preferred time',
          'Outbound Landing Time Around': 'Closeness to preferred time',
          'Return Takeoff Time Around': 'Closer to preferred time',
          'Return Landing Time Around': 'Closeness to preferred time',
          'Outbound & Return Times': 'Closer to preferred times'
        },
        3: {
          Price: 'Cheaper Still',
          'Travel Duration': 'Faster Still',
          Stopovers: 'Even Less Stops',
          'Outbound Takeoff Time Around': 'Even closer to preferred time',
          'Outbound Landing Time Around': 'Closeness to preferred time',
          'Return Takeoff Time Around': 'Even closer to preferred time',
          'Return Landing Time Around': 'Closeness to preferred time',
          'Outbound & Return Times': 'Even closer to preferred times'
        },
        lowest: {
          Price: 'Cheapest',
          'Travel Duration': 'Fastest',
          Stopovers: 'Least Stops',
          'Outbound Takeoff Time Around': 'Even closer to preferred time',
          'Outbound Landing Time Around': 'Even closer to preferred time',
          'Return Takeoff Time Around': 'Even closer to preferred time',
          'Return Landing Time Around': 'Even closer to preferred time',
          'Outbound & Return Times': 'Even closer to preferred times'
        }
      }

      const timeTreeSubTree : any = {
        3: {
          'Outbound Takeoff Time Around': 'Closer to outbound time',
          'Outbound Landing Time Around': 'Closer to outbound time',
          'Return Takeoff Time Around': 'Closer to return time',
          'Return Landing Time Around': 'Closer to return time'
        },
        4: {
          'Outbound Takeoff Time Around': 'Even closer to outbound time',
          'Outbound Landing Time Around': 'Even closer to outbound time',
          'Return Takeoff Time Around': 'Even closer to return time',
          'Return Landing Time Around': 'Even closer to return time'
        }
      }

      const singleFeature : any = {
        Price: 'Cheaper',
        'Travel Duration': 'Faster',
        Stopovers: 'Less Stops',
        'Outbound Takeoff Time Around': 'Closer to preferred time',
        'Outbound Landing Time Around': 'Closer to preferred time',
        'Return Takeoff Time Around': 'Closer to preferred time',
        'Return Landing Time Around': 'Closer to preferred time',
        'Outbound & Return Times': 'Closer to preferred times'
      }

      if (this.level === 1) {
        return featureDescriptionLevels[1][this.$props.itineraryTree?.primaryFeature ?? '']
      } else {
        if (this.$props.itineraryTree?.subTree) {
          const subFeatureLevel = this.level === 3 ? 3 : 4
          return timeTreeSubTree[subFeatureLevel][this.$props.itineraryTree?.primaryFeature ?? '']
        }

        if (!this.$props.itineraryTree?.nextLevelItineraryTrees?.length && !this.$props.itineraryTree?.secondaryFeature) {
          return featureDescriptionLevels.lowest[this.$props.itineraryTree?.primaryFeature ?? '']
        }

        const featureLevel = this.level === 2 ? 2 : 3

        if (this.$props.itineraryTree?.secondaryFeature || featureLevel > 2) {
          return featureDescriptionLevels[featureLevel][this.$props.itineraryTree?.secondaryFeature ?? this.$props.itineraryTree?.primaryFeature ?? '']
        } else {
          return singleFeature[this.$props.itineraryTree?.primaryFeature ?? '']
        }
      }
    }
  }
})
