<template>
  <div class="itinerary row p-2 d-flex align-items-center"
       v-bind:class="[((itineraryTree && itineraryTree.level > 1) || plainItinerary)  ? 'border-top' : '']">
    <template v-if="itineraryTree">
      <div class="col-2 d-flex align-items-center"
         v-bind:class="itineraryTree.nextLevelItineraryTrees.length ? 'cursor-pointer' : ''"
         @click="toggleNextLevel(itineraryTree.id)"
         v-bind:style="['padding-left: ' + ((itineraryTree.level - 1) * 10) + 'px']"
         v-if="showDescriptionRow">
        <div v-if="itineraryTree.nextLevelItineraryTrees.length" style="width: 20px; margin-right: 2px;" :id="[itineraryTree && itineraryTree.level === 1 ? itineraryTree.primaryFeature.replace(/\s/gi, '-') + '-expand-button' : '']">
          <font-awesome-icon class="shadow text-primary" :icon="['far', 'minus-square']" v-if="itineraryTree.showNextLevel" size="lg" style="color: #0d6efd" />
          <font-awesome-icon class="shadow text-primary" :icon="['far', 'plus-square']" v-else size="lg" />
        </div>
        <div v-else-if="level !== 1 " style="width: 15px; margin-right: 2px;">
          <font-awesome-icon icon="circle"  style="margin-right: 5px; font-size: 10px" />
        </div>
        <div style="padding-left: 5px;">
          <div :class="level === 1 ? 'fw-bold' : ''">
            {{ rowDescription() }}
          </div>
          <div v-if="!itineraryTree.showNextLevel && level === 1 && itineraryTree.nextLevelItineraryTrees.length" style="font-size: 12px;">
            ({{ seeMoreOptionsText() }})
          </div>
          <div style="font-size: 8px" v-if="metaData">
            <Popper hover placement="top">
              {{ metaData }}
              <template #content>
                {{ itineraryTree.metadata.feature_percentages }}
              </template>
            </Popper>
          </div>
        </div>
      </div>
    </template>
    <template v-else-if="showDescriptionRow">
      <div class="col-2">
      </div>
    </template>

    <div class="col text-center" :class="[showDescriptionRow ? 'dash' : '', primaryFeature === 'Price' ? 'fw-bold' : '']">
      ${{ itinerary.price }}
    </div>
    <div class="col dash text-center p-2" :class="[primaryFeature === 'Travel Duration' ? 'fw-bold' : '']">
      <Popper hover placement="top" v-if="returnPresent()">
        {{ formatDuration(itinerary.durationAverage) }}
        <template #content>
          <table class="fw-normal">
            <tr><td class="text-end">Outbound:</td><td class="fw-bold">{{ formatDuration(itinerary.outboundTrip.duration) }}</td></tr>
            <tr><td class="text-end">Return:</td><td class="fw-bold">{{ formatDuration(itinerary.returnTrip.duration) }}</td></tr>
          </table>
        </template>
      </Popper>
      <div v-else>
        {{ formatDuration(itinerary.durationAverage) }}
      </div>

    </div>
    <div class="col dash text-center" :class="[primaryFeature === 'Stopovers' ? 'fw-bold' : '']">
      {{ itinerary.totalStops }} {{ t('itinerary.stop', itinerary.totalStops) }}
    </div>
    <div class="col-3 px-2 dash">
      <TripBox :trip="itinerary.outboundTrip" :bold="primaryFeature.includes('Outbound') && primaryFeature.includes('Time')" />
    </div>
    <div class="col-3 px-1 dash" v-if="itinerary.returnTrip">
      <TripBox :trip="itinerary.returnTrip" :bold="primaryFeature.includes('Return') && primaryFeature.includes('Time')" />
    </div>
    <div class="col dash p-2 d-inline-flex justify-content-evenly align-items-center text-center">
      <Popper hover placement="top">
        <div @click="toggleFavourite(itinerary.id)" class="cursor-pointer">
          <div v-if="itinerary.favourite" style="line-height: 5px">
            <font-awesome-icon :icon="['fas', 'heart']" size="lg" class="text-secondary"/>
            <span v-if="showFavouriteRemove" style="font-size: 10px">(remove)</span>
          </div>
          <font-awesome-icon v-else :icon="['far', 'heart']" size="lg" class="text-secondary"/>
        </div>
        <template #content>
          Add to the list of itineraries to compare.
        </template>
      </Popper>
      <a class="btn btn-primary ms-2 px-2 py-0" :href="itinerary.link" target="_blank">Book</a>
    </div>
  </div>
</template>

<script lang="ts">
import { ref, defineComponent, PropType } from 'vue'
import { useI18n } from 'vue-i18n'
import ItineraryTree from '@/types/ItineraryTree'
import { mapGetters, mapMutations } from 'vuex'
import TripBox from '@/components/TripBox.vue'
import Itinerary from '@/types/Itinerary'
import { useStore } from '@/store'
import DurationHelperMixin from '@/helpers/DurationHelpers'
import _ from 'lodash'

export default defineComponent({
  name: 'ItineraryRow',
  props: {
    itineraryTree: { type: Object as PropType<ItineraryTree>, required: false },
    plainItinerary: { type: Object as PropType<Itinerary>, required: false },
    showDescriptionRow: { type: Boolean, required: true },
    showFavouriteRemove: { type: Boolean, required: false }
  },
  components: {
    TripBox
  },
  mixins: [DurationHelperMixin],
  setup (props) {
    const { t } = useI18n({
      locale: 'en',
      messages: {
      }
    })

    const store = useStore()
    const itinerary = ((props.itineraryTree) ? ref(store.getters.getItinerary(props.itineraryTree.itineraryID) as Itinerary)
      : ref(props.plainItinerary as Itinerary))
    const level = props?.itineraryTree?.level ?? 0

    let metaData = ''
    if (!_.isEmpty(props.itineraryTree?.metadata)) {
      metaData += 'Avg: ' + props.itineraryTree?.metadata?.percentage_average + '% '
      metaData += ' --- '
      _.forOwn(props.itineraryTree?.metadata?.importances, (v, k) => { metaData += k + ': ' + v + ', ' })
    }

    return { t, itinerary, level, metaData }
  },
  computed: {
    primaryFeature () : string {
      return this.itineraryTree?.primaryFeature || ''
    }
  },
  methods: {
    ...mapMutations(['toggleNextLevel', 'toggleFavourite']),
    ...mapGetters(['getItinerary', 'returnPresent']),

    seeMoreOptionsText () {
      if (this.$props.plainItinerary) return ''
      if (!this.$props.itineraryTree) return ''

      const seeMoreText : any = {
        Price: 'similar options',
        'Travel Duration': 'similar options',
        Stopovers: 'options with less stops',
        'Outbound Takeoff Time Around': 'options closer to preferred times',
        'Outbound Landing Time Around': 'options closer to preferred times',
        'Return Takeoff Time Around': 'options closer to preferred times',
        'Return Landing Time Around': 'options closer to preferred times',
        'Outbound & Return Times': 'options closer to preferred times'
      }

      const prefix = this.$props.itineraryTree.showNextLevel ? 'Hide' : 'See'
      return prefix + ' ' + 'similar options' // seeMoreText[this.$props.itineraryTree.primaryFeature]
    },
    rowDescription () {
      if (this.$props.plainItinerary) return ''

      const featureDescriptionLevels : any = {
        1: {
          Price: 'Value for Price',
          'Travel Duration': 'Value for Duration',
          Stopovers: 'Value for Stops',
          'Outbound Takeoff Time Around': 'Closeness to preferred time',
          'Outbound Landing Time Around': 'Closeness to preferred time',
          'Return Takeoff Time Around': 'Closeness to preferred time',
          'Return Landing Time Around': 'Closeness to preferred time',
          'Outbound & Return Times': 'Closeness to preferred times'
        },
        2: {
          Price: 'Cheaper',
          'Travel Duration': 'Faster',
          Stopovers: 'Less Stops',
          'Outbound Takeoff Time Around': 'Closer to preferred time',
          'Outbound Landing Time Around': 'Closeness to preferred time',
          'Return Takeoff Time Around': 'Closer to preferred time',
          'Return Landing Time Around': 'Closeness to preferred time',
          'Outbound & Return Times': 'Closer to preferred times'
        },
        3: {
          Price: 'Cheaper Still',
          'Travel Duration': 'Faster Still',
          Stopovers: 'Even Less Stops',
          'Outbound Takeoff Time Around': 'Even closer to preferred time',
          'Outbound Landing Time Around': 'Closeness to preferred time',
          'Return Takeoff Time Around': 'Even closer to preferred time',
          'Return Landing Time Around': 'Closeness to preferred time',
          'Outbound & Return Times': 'Even closer to preferred times'
        },
        lowest: {
          Price: 'Cheapest',
          'Travel Duration': 'Fastest',
          Stopovers: 'Least Stops',
          'Outbound Takeoff Time Around': 'Even closer to preferred time',
          'Outbound Landing Time Around': 'Even closer to preferred time',
          'Return Takeoff Time Around': 'Even closer to preferred time',
          'Return Landing Time Around': 'Even closer to preferred time',
          'Outbound & Return Times': 'Even closer to preferred times'
        }
      }

      const timeTreeSubTree : any = {
        3: {
          'Outbound Takeoff Time Around': 'Closer to outbound time',
          'Outbound Landing Time Around': 'Closer to outbound time',
          'Return Takeoff Time Around': 'Closer to return time',
          'Return Landing Time Around': 'Closer to return time'
        },
        4: {
          'Outbound Takeoff Time Around': 'Even closer to outbound time',
          'Outbound Landing Time Around': 'Even closer to outbound time',
          'Return Takeoff Time Around': 'Even closer to return time',
          'Return Landing Time Around': 'Even closer to return time'
        }
      }

      const singleFeature : any = {
        Price: 'Cheaper',
        'Travel Duration': 'Faster',
        Stopovers: 'Less Stops',
        'Outbound Takeoff Time Around': 'Closer to preferred time',
        'Outbound Landing Time Around': 'Closer to preferred time',
        'Return Takeoff Time Around': 'Closer to preferred time',
        'Return Landing Time Around': 'Closer to preferred time',
        'Outbound & Return Times': 'Closer to preferred times'
      }

      if (this.level === 1) {
        return featureDescriptionLevels[1][this.$props.itineraryTree?.primaryFeature ?? '']
      } else {
        if (this.$props.itineraryTree?.subTree) {
          const subFeatureLevel = this.level === 3 ? 3 : 4
          return timeTreeSubTree[subFeatureLevel][this.$props.itineraryTree?.primaryFeature ?? '']
        }

        if (!this.$props.itineraryTree?.nextLevelItineraryTrees?.length && !this.$props.itineraryTree?.secondaryFeature) {
          return featureDescriptionLevels.lowest[this.$props.itineraryTree?.primaryFeature ?? '']
        }

        const featureLevel = this.level === 2 ? 2 : 3

        if (this.$props.itineraryTree?.secondaryFeature || featureLevel > 2) {
          return featureDescriptionLevels[featureLevel][this.$props.itineraryTree?.secondaryFeature ?? this.$props.itineraryTree?.primaryFeature ?? '']
        } else {
          return singleFeature[this.$props.itineraryTree?.primaryFeature ?? '']
        }
      }
    }
  }
})
</script>

<style scoped>

.itinerary {
  font-size: 15px;
}

.dash {
  border-left: gray dashed 1px;
}

</style>
