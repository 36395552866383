import _round from 'lodash/round'

export default {
  methods: {
    formatDuration (durationInSeconds : number) {
      const hours = _round(Math.floor(durationInSeconds / 3600.0))
      const minutes = _round((durationInSeconds / 60) % 60)
      return hours + 'h ' + minutes + 'm'
    }
  }
}
