
import { computed, defineComponent, ref } from 'vue'
import { useStore } from '@/store'
import _round from 'lodash/round'
import { mapGetters, mapMutations } from 'vuex'

export default defineComponent({
  name: 'MinValues',
  setup () {
    const store = useStore()
    const featureMinMaxes = ref(store.state.featureMinMaxes)
    return { featureMinMaxes }
  },
  methods: {
    ...mapGetters(['returnPresent']),
    durationDisplay (durationInSeconds : number) {
      if (this.returnPresent()) durationInSeconds /= 2
      durationInSeconds /= 60
      return `${_round(Math.floor(durationInSeconds / 60.0))}h ${_round(durationInSeconds % 60.0)}m`
    }
  }
})
