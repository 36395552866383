
import { defineComponent, PropType } from 'vue'
import ItineraryTree from '@/types/ItineraryTree'
import ItineraryRow from '@/components/ItineraryRow.vue'
import { mapGetters } from 'vuex'

export default defineComponent({
  name: 'ItineraryStructure',
  components: {
    ItineraryRow
  },
  props: {
    itineraryTree: { type: Object as PropType<ItineraryTree>, required: true }
  },
  methods: {
    ...mapGetters(['returnPresent'])
  }
})
