<template>
  <div class="d-flex justify-content-between">
    <template v-if="trip.flights.length === 1">
      <div class="d-flex flex-column justify-content-end align-items-center text-center">
        <Popper hover placement="top">
          <div>
            <div class="" style="font-size: 13px;" :class="bold ? 'fw-bold' : ''" v-html="formatDate(trip.flights[0].leavingAt)">
            </div>
            <div :class="bold ? 'fw-bold' : ''" style="font-size: 10px;">
              {{ trip.flights[0].fromAirport }}
            </div>
          </div>
          <template #content>
            {{ airportNames[trip.flights[0].fromAirport] }}<br />
            Leaving: <strong>{{ formatDateWithDay(trip.flights[0].leavingAt) }}</strong>
          </template>
        </Popper>
      </div>
      <div class="d-flex flex-column justify-content-end align-items-center text-center px-2 flex-grow-1">
          <div style="height: 20px;">
            <TripBoxFlightInfo :flight="trip.flights[0]" />
          </div>
          <div class="text-center pb-2 px-1 w-100">
            <FlightArrow />
          </div>
      </div>
      <div class="d-flex flex-column justify-content-end align-items-center text-center">
        <Popper hover placement="top">
          <div>
            <div class="" style="font-size: 13px;" :class="bold ? 'fw-bold' : ''" v-html="formatDate(trip.flights[0].arrivingAt)">
            </div>
            <div :class="bold ? 'fw-bold' : ''" style="font-size: 10px;">
              {{ trip.flights[0].toAirport }}
            </div>
          </div>
          <template #content>
            {{ airportNames[trip.flights[0].toAirport] }}<br />
            Arriving: <strong>{{ formatDateWithDay(trip.flights[0].arrivingAt) }}</strong>
          </template>
        </Popper>
      </div>
    </template>
    <template v-if="trip.flights.length > 1">
      <div class="d-flex flex-column justify-content-end align-items-center text-center pe-1">
        <Popper hover placement="top">
          <div>
            <div class="" style="font-size: 13px;" :class="bold ? 'fw-bold' : ''">
              {{ formatDate(trip.flights[0].leavingAt) }}
            </div>
            <div :class="bold ? 'fw-bold' : ''" style="font-size: 10px;">
              {{ trip.flights[0].fromAirport }}
            </div>
          </div>
          <template #content>
            {{ airportNames[trip.flights[0].fromAirport] }}<br />
            Leaving: <strong>{{ formatDateWithDay(trip.flights[0].leavingAt) }}</strong>
          </template>
        </Popper>
      </div>
      <div class="d-flex flex-column justify-content-end align-items-center text-center flex-grow-1">
        <div style="height: 20px;">
          <TripBoxFlightInfo :flight="trip.flights[0]" />
        </div>
        <div class="text-center pb-2 px-1 w-100">
          <FlightArrow />
        </div>
      </div>

      <template v-for="flight in trip.flights.slice(1, trip.flights.length)" v-bind:key="flight.id">
        <div class="d-flex flex-column justify-content-end align-items-center text-center" >
          <TripBoxStopInfo :flight="flight" />
        </div>
        <div class="d-flex flex-column justify-content-end align-items-center text-center flex-grow-1">
          <div style="height: 20px;">
            <TripBoxFlightInfo :flight="flight" />
          </div>
          <div class="text-center pb-2 px-1 w-100">
            <FlightArrow />
          </div>
        </div>
      </template>

      <div class="d-flex flex-column justify-content-end align-items-center text-center ps-1">
        <Popper hover placement="top">
          <div>
            <div class="" style="font-size: 13px;" :class="bold ? 'fw-bold' : ''">
              {{ formatDate(trip.flights[trip.flights.length - 1].arrivingAt) }}
            </div>
            <div :class="bold ? 'fw-bold' : ''" style="font-size: 10px;">
              {{ trip.flights[trip.flights.length - 1].toAirport }}
            </div>
          </div>
          <template #content>
            {{ airportNames[trip.flights[trip.flights.length - 1].toAirport] }}<br />
            Arriving: <strong>{{ formatDateWithDay(trip.flights[trip.flights.length - 1].arrivingAt) }}</strong>
          </template>
        </Popper>
      </div>

    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import Trip from '@/types/Trip'
import FlightArrow from '@/components/FlightArrow.vue'
import DateHelperMixin from '../helpers/DateHelpers'
import DurationHelperMixin from '../helpers/DurationHelpers'
import airportNames from './../assets/airports.json'
import TripBoxFlightInfo from '@/components/TripBox.FlightInfo.vue'
import TripBoxStopInfo from '@/components/TripBox.StopInfo.vue'

export default defineComponent({
  name: 'TripBox',
  components: { FlightArrow, TripBoxFlightInfo, TripBoxStopInfo },
  mixins: [DateHelperMixin, DurationHelperMixin],
  setup () {
    return { airportNames }
  },
  props: {
    trip: { type: Object as PropType<Trip>, required: true },
    bold: { type: Boolean, required: true }
  }
})
</script>

<style scoped>

</style>
