<template>
  <div class="container min-vh-80">
    <h2 class="p-2 text-center">Our Approach</h2>
    <h4>How It Works</h4>
    <p>We help you focus on flight options that actually make sense.</p>
    <p>Instead of manually looking through thousands of options with outdated sorting and filtering tools, compare the sensible ones easily and let us do the work.</p>

    <h4>Reasonable Options</h4>
    <p>Without getting all math-sy, there are a LOT of flights out there that you would never want to consider because a better option is obvious when you compare them side-by-side.*</p>
    <p>
      The problem is that as a human, you can't do this with thousands of things at once!
      <br/>
      That's where Reagent is different—with computers we can.
    </p>
    <p>
      Here's the basic idea.<br />
      For now let's say you only care about the <strong>price</strong> and <strong>travel time</strong>.<br />
      (You probably care about a lot more than this, but stick with me.)
    </p>
    <p>
      If I gave you the following flight options, the choice is pretty obvious.<br />
      Consider just these two flights:<br />
      <strong>Flight 1:</strong> <span class="badge rounded-pill bg-primary p-2 mt-2 mb-2">$300 | 6 hours</span><br />
      <strong>Flight 2:</strong> <span class="badge rounded-pill bg-primary p-2 my-1">$200 | 6 hours</span>
      <span class="p-1"><font-awesome-icon icon="arrow-left" class="mx-1" />Obvious choice</span>
    </p>
    <p>
      Now just these:<br />
      <strong>Flight 3:</strong> <span class="badge rounded-pill bg-primary p-2 mt-2 mb-2">$250 | 5 hours</span><br />
      <strong>Flight 4:</strong> <span class="badge rounded-pill bg-primary p-2 my-1">$250 | 4 hours</span>
      <span class="p-1"><font-awesome-icon icon="arrow-left" class="mx-1" />Obvious choice</span>
    </p>
    <p>
      And finally these:<br/>
      <strong>Flight 5:</strong> <span class="badge rounded-pill bg-primary p-2 mt-2 mb-2">$400 | 3 hours</span>
      <span class="p-1"><font-awesome-icon icon="arrow-left" class="mx-1" />Obvious choice</span><br />
      <strong>Flight 6:</strong> <span class="badge rounded-pill bg-primary p-2 my-1">$400 | 4 hours</span>
    </p>
    <p>
      If we look at these visually, the "obvious" choices form a boundary line:<br />
      <img src="../assets/our-approach/flight-options.png" class="pt-3 img-fluid" style="max-width: 700px; width: 100%" />
    </p>
    <p>
      Given just price and travel time as considerations, you would never choose any flight to the right of this line.
    </p>
    <p>
      Depending on what is important to you, any of these three options could be good:
    </p>
    <ul>
      <li>If you are really price sensitive, you'd choose the $200/6 hour flight.</li>
      <li>If you need to get there fast, you'd choose the $400/3 hour option.</li>
      <li>If you want the best of both worlds, then you'd choose the $250/4 hour itinerary.</li>
    </ul>
    <p>
      Now let's make it a bit more complicated and look at the actual options for a flight from LA to London and back:<br />
      <img src="../assets/our-approach/la-lon.png" class="pt-3 img-fluid" style="max-width: 700px; width: 100%" />
    </p>
    <p>
      Out of the 1067 flight options, you would really only want to consider 19 of them!
    </p>
    <p>
      Of course, this is if you cared about only price and travel time.
    </p>
    <p>
      It starts to get <i>really</i> complicated as we add more considerations to the mix, as each additional desire influences all of the others.
    </p>
    <p>
      But that's what makes us different...
    </p>
    <h4>Similar Options</h4>
    <p>
      In order to help make your decision easier, we present you with starting points that have been optimized for:
    </p>
    <ul>
      <li>Price</li>
      <li>Travel Time</li>
      <li>Departure/Arrival Times</li>
      <li>Layovers (if relevant)</li>
    </ul>
    <p>Each starting point is chosen by our computer model that has been programmed to approximate how people like you evaluate flights.</p>
    <p>
      The model is based on extensive research into the psychology behind what makes a "good" flight and we're really proud of it.
    </p>
    <p>
      But I digress. What <i>you</i> probably care about is that these starting points are likely the options you'd get if you went to a travel agent with the same search criteria.
    </p>
    <p>
      From here, we give you similar options in each category that emphasize different factors.<br />
      Depending on the available flights, there may be a lot of additional options, or there may be none.
    </p>
    <p>
      I like to think of these "similar options" as what I'd get back from a travel agent after reviewing a suggestion and replying with "that looks ok, but is there anything cheaper/faster/with a better departure time/etc...?"
    </p>

    <h4>Comparison</h4>
    <p>
      As you investigate each of the levels in the main groupings, there's a little heart icon to flag any of the flights that you might be interested in.
    </p>
    <p>
      Once you've hearted a few, you can easily compare them side by side by tapping on the compare button at the bottom.
    </p>

    <h4>That's It!</h4>
    <p>
      At this point you've narrowed your search down from thousands, to dozens, to a handful, to ultimately just one - the right flight for you - all in a matter of minutes.
    </p>
    <p>
      Rest easy, knowing that you're searching smarter, not harder.
    </p>
    <p class="pb-5 fw-bold">
      Happy flight-finding!<br />
      ~ The Reagent team.
    </p>
    <p class="pt-3 text-secondary">
      <strong>*</strong> * Using the traditional filter and sort method found on almost every travel site out there, you are forced to exclude options that you might actually want, just to get the list down to a reasonable size—something that you can evaluate.<br />
      For instance:
    </p>
    <ul class="text-secondary">
      <li>If you want to leave in the morning and add a filter for 8-11am, you could miss seeing the flight that departed at 7:55  which had one less layover.</li>
      <li>Or the flight that left at 4pm but cost half the price—something that's probably at least worth consideration depending on your schedule.</li>
    </ul>
    <p class="text-secondary">
      Using a legacy search, you'd probably never see these options because you're forced to artificially limit what results you manually compare because there are just too many to sift through!
    </p>
  </div>
  <Footer />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Footer from '@/components/Footer.vue'

export default defineComponent({
  name: 'OurApproachPage',
  components: { Footer }
})
</script>

<style scoped>
</style>
