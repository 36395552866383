<template>
  <footer class="footer bg-white border-top pt-2" style="min-height: 100px">
    <div class="container">
      <div class="row">
        <div class="col-6 col-sm-3">
          <router-link to="/our-approach" class="nav-link text-body p-0 py-2">Our Approach</router-link>
          <router-link to="/about" class="nav-link text-body p-0 py-2">About</router-link>
        </div>
        <div class="col-6 col-sm-3">
          <router-link to="/contact" class="nav-link text-body p-0 py-2">Contact Us</router-link>
          <router-link to="/email-signup" class="nav-link text-body p-0 py-2">Product Updates</router-link>
        </div>
      <div class="col-6 col-sm-3">
        <router-link to="/terms-and-conditions" class="nav-link text-body p-0 py-2">Terms & Conditions</router-link>
        <span class="text-secondary" style="font-size: 11px">{{ new Date().getFullYear() }} © reagent.travel</span>
      </div>
      </div>
    </div>
  </footer>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Footer'
})
</script>

<style scoped>

.nav-link {
  font-size: 13px;
}

</style>
