<template>
  <div class="container min-vh-80">
    <h2 class="p-2 text-center">Affiliate Policy</h2>
    <p>
      Where possible, links to itineraries are affiliate in nature. This is to pay for the operation of reagent.travel. That said, we'll never omit an itinerary simply because there's no affiliate program available. Our goal is to gather as much information as possible and present it in the most accessible way possible - ultimately rendering your research process as painless as it can be.
    </p>
  </div>
  <Footer />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Footer from '@/components/Footer.vue'

export default defineComponent({
  name: 'AffiliatePolicyPage',
  components: {
    Footer
  }
})
</script>

<style scoped>
</style>
