<template>
  <Popper hover placement="top" :bold="bold">
    <div style="font-size: 9px;" :class="bold ? 'fw-bold' : ''">
      {{ flight.fromAirport }}
    </div>
    <template #content>
      {{ airportNames[flight.fromAirport] }}
      <br />
      Stopover: <strong>{{ formatDuration(flight.stopoverDuration) }}</strong>
    </template>
  </Popper>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from 'vue'
import airportNames from './../assets/airports.json'
import Flight from '@/types/Flight'
import DurationHelperMixin from '@/helpers/DurationHelpers'

export default defineComponent({
  name: 'TripBoxStopInfo',
  mixins: [DurationHelperMixin],
  props: {
    bold: { type: Boolean, required: true },
    flight: { type: Object as PropType<Flight>, required: true }
  },
  setup () {
    return { airportNames }
  }
})
</script>

<style scoped>
</style>
