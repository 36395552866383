
import { defineComponent, PropType, ref } from 'vue'
import airlineNames from './../assets/airlines.json'
import Flight from '@/types/Flight'
import DurationHelperMixin from '@/helpers/DurationHelpers'

export default defineComponent({
  name: 'TripBoxFlightInfo',
  mixins: [DurationHelperMixin],
  props: {
    flight: { type: Object as PropType<Flight>, required: true }
  },
  setup () {
    return { airlineNames }
  }
})
