<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-white border-bottom">
    <div class="container">
      <router-link to="/" class="navbar-brand p-0 m-0 cursor-pointer" style="color: #F3933F">
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" width="40px"
             viewBox="0 0 500 500" style="enable-background:new 0 0 500 500;" xml:space="preserve">
          <g>
          <path class="fill-secondary" d="M248.51,455.04c-8.39-4.83-16.77-9.67-25.17-14.47c-10.25-5.86-20.54-11.66-30.78-17.53
            c-0.59-0.34-1.15-0.72-1.72-1.14c-1.51-1.1-1.38-3.4,0.23-4.35v0c36.66-21.34,73.32-42.68,110.02-63.96
            c19.32-11.21,38.68-22.36,58.08-33.43c4.26-2.43,6.85-6.99,6.77-11.9c-0.36-20.51-0.59-41.03-0.83-61.54c0-0.02,0-0.04,0-0.06
            c-0.03-3.03,3.21-4.97,5.87-3.53c9.8,5.31,42.2,23.79,54.37,30.74c2.89,1.65,4.66,4.72,4.64,8.04c-0.02,4.19-0.03,8.73-0.03,12.32
            c-0.01,18.34-0.02,36.68-0.03,55.02c0,2.69-1.43,5.18-3.76,6.53l-0.04,0.02c-21.28,12.36-42.55,24.71-63.84,37.05
            c-27.18,15.75-54.4,31.44-81.53,47.27c-8.18,4.77-16.18,9.84-24.25,14.81C254.07,456.44,251,456.47,248.51,455.04z"/>
            <path class="fill-secondary" d="M430.98,218.33c0,1.88-2.01,3.07-3.66,2.17c-7.23-3.96-14.5-7.86-21.64-11.99
            c-27.81-16.07-55.55-32.27-83.35-48.37c-20.74-12.02-41.63-23.79-62.22-36.06c-5.4-3.22-9.55-3.41-15.02-0.14
            c-18.28,10.93-36.86,21.36-55.34,31.95l0,0c-1.53,0.87-3.46-0.13-3.62-1.88c-0.06-0.72-0.11-1.39-0.11-2.07
            c-0.03-21.16,0.07-42.32-0.1-63.48c-0.03-3.87,1.33-5.92,4.67-7.81c18.23-10.29,54.76-31.68,59.82-34.75
            c0.11-0.07,0.38-0.17,0.71-0.27c1.35-0.43,2.81-0.4,4.12,0.14c0.14,0.06,0.25,0.11,0.32,0.15c4.7,3,35.28,21.2,49.86,29.67
            c21.43,12.45,42.83,24.93,64.26,37.37c20.01,11.63,40.03,23.23,60.05,34.84c0.76,0.44,1.23,1.26,1.23,2.14V218.33z"/>
            <path class="fill-secondary" d="M72,350.56c0-67.94,0-135.87,0-203.81c0-0.78,0.41-1.5,1.08-1.91c6.62-3.99,13.22-8.02,19.88-11.95
            c12.89-7.61,25.82-15.17,38.74-22.74c0.21-0.13,0.43-0.24,0.67-0.36c1.38-0.7,3.03,0.19,3.22,1.72c0.17,1.39,0.31,2.66,0.31,3.93
            c0.03,63.64,0.12,127.27-0.11,190.91c-0.02,6.19,1.96,9.51,7.36,12.47c18.97,10.4,37.62,21.36,56.38,32.14
            c0.01,0,0.02,0.01,0.03,0.01c1.45,0.84,1.38,2.97-0.06,3.82c-7.53,4.39-14.78,8.64-22.05,12.84c-11.81,6.83-23.7,13.54-35.44,20.5
            c-2.59,1.54-4.48,1.87-7.24,0.25c-20.12-11.75-40.35-23.3-60.53-34.94c-0.58-0.33-1.07-0.82-1.57-1.3
            C72.25,351.73,72,351.16,72,350.56z"/>
            <path class="fill-primary" d="M212.29,266c3.44,14.93,9.74,28.26,19.33,40.13c9.52,11.79,21.5,20.05,35.51,25.66
            c-0.01,0.44-0.02,0.88-0.03,1.33c-2.59,0.45-5.17,1.17-7.78,1.32c-12.2,0.68-24.19-0.52-35.96-3.98c-4.88-1.43-6.79-5.74-9.23-9.29
            c-5.83-8.48-9.31-17.98-9.85-28.35C203.77,283.06,206.39,274.13,212.29,266z"/>
            <path class="fill-primary" d="M329.4,276.68c-3.74,13.61-10.35,25.27-20.82,34.19c-3,2.56-7.88,3.4-12.04,4.02
            c-17.17,2.56-38.5-4.48-48.13-19.9c-0.51-0.82-0.91-1.7-2.14-4.01C276.97,299.6,304.48,295.52,329.4,276.68z"/>
            <path class="fill-primary" d="M287.26,234.76c-2.49-14.47-7.87-27.69-16.19-39.68c-8.33-12-19.53-20.49-32.62-26.88
            c13.49-3.08,26.42-0.96,39.2,2.9c0.91,0.28,1.88,0.84,2.5,1.55c11.99,13.65,18.79,29.01,14.64,47.53
            c-1.13,5.05-3.56,9.81-5.39,14.7C288.69,234.84,287.98,234.8,287.26,234.76z"/>
            <path class="fill-primary" d="M218.18,225.05c-24.23,20.2-36.5,45.04-33.47,76.56c-0.4,0.06-0.79,0.12-1.19,0.19
            c-1.72-2.32-3.71-4.49-5.12-6.99c-5.09-9.02-9.29-18.43-10.87-28.78c-0.27-1.75-0.44-3.8,0.21-5.35
            c5.46-12.99,12.79-24.54,25.93-31.12C201.04,225.87,208.69,223.21,218.18,225.05z"/>
            <path class="fill-primary" d="M173.56,218.1c4.32-12.81,12.42-22.75,22.2-31.43c1.25-1.11,3.35-1.45,5.12-1.73
            c16.56-2.65,31.82,0.55,44.84,11.35c4.22,3.5,7.23,8.44,10.43,13.25C227.17,200.48,199.69,201.64,173.56,218.1z"/>
            <path class="fill-primary" d="M284.75,273.28c21.81-20.1,33.62-44.29,30.26-74.7c0.43-0.03,0.86-0.06,1.28-0.09
            c1.46,1.91,3.21,3.68,4.33,5.77c3.06,5.72,5.9,11.55,8.75,17.38c4.67,9.55,1.38,18.24-3.43,26.46
            c-6.87,11.74-16.45,20.47-29.81,24.34c-3.44,1-7.06,1.41-10.59,2.09C285.27,274.13,285.01,273.71,284.75,273.28z"/>
        </g>
        </svg>
      </router-link>

      <ul class="navbar-nav mb-2 mb-lg-0">
        <li class="nav-item">
          <router-link to="/" class="nav-link active text-primary cursor-pointer">
            <h4 class="float-start fancy-font">
              Reagent
            </h4>
            <div class="float-start ps-1 text-primary" style="font-size: 8px; margin-top: 15px">(beta)</div>
          </router-link>
        </li>
      </ul>

      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav ms-auto">
          <li class="nav-item px-2 border-end">
            <router-link to="/our-approach" class="nav-link cursor-pointer">Our Approach</router-link>
          </li>
          <li class="nav-item px-2 border-end">
            <router-link to="/email-signup" class="nav-link cursor-pointer">Product Updates</router-link>
          </li>
          <li class="nav-item ps-2">
            <router-link to="/contact" class="nav-link cursor-pointer">Contact</router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapMutations } from 'vuex'

export default defineComponent({
  name: 'Header',
  emits: ['resetForm'],
  methods: {
    ...mapMutations(['clearItineraries', 'incrementResetFormInteger']),
    resetSearch () {
      this.$router.push({ path: 'search' })
      this.clearItineraries()
      this.$emit('resetForm')
    }
  }
})
</script>

<style scoped>

</style>
