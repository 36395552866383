<template>
  <div class="container min-vh-80 text-center">
    <div class="row">
      <h2 class="p-2 pt-5 text-center">Signup For Product Updates</h2>
      <p>
        Want to know when we release new features or add additional options to Reagent? <br />
        Enter you email below, and we will keep you up to date!
      </p>
    </div>
    <div class="row d-flex justify-content-center">
      <div class="col-12 col-sm-6">
        <form @submit.prevent="submitEmail(false)">
          <div class="input-group mb-3 text-center">
            <input type="text" class="form-control border-dark" placeholder="Email" v-model="email">
            <button type="submit" class="btn btn-primary">Signup</button>
          </div>
          <p v-if="successMessage" class="text-secondary">{{ successMessage }}</p>
          <p v-else-if="errorMessage" class="text-danger">{{ errorMessage }}</p>
        </form>
      </div>
    </div>
  </div>
  <Footer />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Footer from '@/components/Footer.vue'
import { mapMutations } from 'vuex'

export default defineComponent({
  name: 'EmailSignupPage',
  components: {
    Footer
  },
  data () {
    return {
      email: '',
      successMessage: '',
      errorMessage: ''
    }
  },
  methods: {
    submitEmail () {
      this.errorMessage = ''
      this.successMessage = ''

      this.axios.post(process.env.VUE_APP_API_BASE_PATH + '/email-signup-create',
        { email: this.email }).then(response => {
        this.successMessage = 'Email Submitted Successfully'
      }).catch(() => {
        this.errorMessage = 'There was an error submitting your email, please try again.'
      })
    }
  }
})
</script>

<style scoped>
</style>
