
import { defineComponent, PropType } from 'vue'
import Trip from '@/types/Trip'
import FlightArrow from '@/components/FlightArrow.vue'
import DateHelperMixin from '../helpers/DateHelpers'
import DurationHelperMixin from '../helpers/DurationHelpers'
import airportNames from './../assets/airports.json'
import TripBoxFlightInfo from '@/components/TripBox.FlightInfo.vue'
import TripBoxStopInfo from '@/components/TripBox.StopInfo.vue'

export default defineComponent({
  name: 'TripBox',
  components: { FlightArrow, TripBoxFlightInfo, TripBoxStopInfo },
  mixins: [DateHelperMixin, DurationHelperMixin],
  setup () {
    return { airportNames }
  },
  props: {
    trip: { type: Object as PropType<Trip>, required: true },
    bold: { type: Boolean, required: true }
  }
})
