
import { computed, defineComponent, ref } from 'vue'
import _ from 'lodash'
import LocationResults from '@/types/LocationResults'
import { mapGetters, mapMutations } from 'vuex'
import AirlineSelector from '@/components/AirlineSelector.vue'
import { store } from '@/store'
import { useRoute } from 'vue-router'
import dayjs from 'dayjs'

function initialState () {
  const departureDate = new Date()
  departureDate.setDate(departureDate.getDate() + 7)

  const returnDate = new Date()
  returnDate.setDate(returnDate.getDate() + 14)

  return {
    query: {
      oneWay: false as boolean,
      outbound: {
        airport: [] as string[],
        date: departureDate,
        time: 'departure:0900-1200'
      },
      return: {
        airport: [] as string[],
        date: returnDate,
        time: 'anytime'
      },
      passengers: {
        adults: 1,
        children: 0,
        infants: 0
      }
    },
    errors: {
      submitClicked: false,
      outbound: {
        airport: ''
      },
      return: {
        airport: ''
      }
    }
  }
}

export default defineComponent({
  name: 'TopForm',
  components: { AirlineSelector },
  data () {
    return initialState()
  },
  watch: {
    'query.outbound.date': function () {
      if (this.query.outbound.date > this.query.return.date) {
        this.query.return.date = this.query.outbound.date
      }
    }
  },
  computed: {
    passengersDisplay () {
      let adultsDisplay = ''
      let childrenDisplay = ''
      let infantsDisplay = ''
      if (this.query.passengers.adults > 0) {
        adultsDisplay = this.query.passengers.adults + ' Adult'
        if (this.query.passengers.adults > 1) adultsDisplay += 's'
      }
      if (this.query.passengers.children > 0) {
        childrenDisplay = this.query.passengers.children + ' Child'
        if (this.query.passengers.children > 1) childrenDisplay += 'ren'
      }
      if (this.query.passengers.infants > 0) {
        infantsDisplay = this.query.passengers.infants + ' Infant'
        if (this.query.passengers.infants > 1) infantsDisplay += 's'
      }

      return _.filter([adultsDisplay, childrenDisplay, infantsDisplay], (display) => { return display !== '' }).join(', ')
    }
  },
  setup () {
    const route = useRoute()
    const debug = route.query.debug === 'true'
    const travellerType = route.query.traveller_type ?? 'budget'

    const showAirlinesModal = computed(() => store.state.showAirlinesModal)

    const showPassengerDropdown = ref(false)
    const selectSettings = {
      tags: false,
      multiple: true,
      theme: 'bootstrap-5',
      tokenSeparators: [],
      maximumSelectionLength: 1,
      minimumInputLength: 1,
      minimumResultsForSearch: 20,
      dropdownAutoWidth: true,
      selectionCssClass: 'select2--small',
      dropdownCssClass: 'select2--small',
      templateSelection: (o: any) => {
        if (o.postfix == null) { // 'Searching' or 'no results' (Not one of our locations)
          return o.text
        } else {
          return o.text + o.postfix
        }
      },
      templateResult: function (o: any) {
        if (o.postfix == null) { // 'Searching' or 'no results' (Not one of our locations)
          return o.text
        } else {
          return o.text + o.postfix
        }
      },
      escapeMarkup: function (m: any) { return m },
      language: { inputTooShort: function () { return 'Please enter airport or city' } },
      ajax: {
        url: process.env.VUE_APP_API_BASE_PATH + '/search-airports',
        delay: 240,
        dataType: 'json',
        type: 'GET',
        data: function (params: Record<string, unknown>) {
          return { term: params.term }
        },
        processResults: function (data: LocationResults) {
          const locations = _.filter(data.locations, function (location) { return location.code !== null }) // Some cities don't have a code.
          return {
            results: _.map(locations, function (location) {
              let countryCode = ''
              if (_.has(location, 'city') && _.has(location.city, 'country')) {
                countryCode = location.city.country.code
              } else if (_.has(location, 'country')) {
                countryCode = location.country.code
              }

              let name = location.name.replace(/international/i, 'Int')
              name = _.truncate(name, { length: 35 })
              if (location.type === 'city') {
                const postfix = ', ' + countryCode + ' (' + location.code + ', All Airports)'

                return {
                  text: name,
                  id: name + postfix + '**city:' + location.code,
                  icon_code: 'city',
                  postfix: postfix
                }
              } else if (location.type === 'airport') {
                const postfix = ', ' + countryCode + ' (' + location.code + ')'

                return {
                  text: name,
                  id: name + postfix + '**airport:' + location.code,
                  icon_code: 'plane-departure',
                  postfix: postfix
                }
              }
            })
          }
        }
      }
    }

    return { selectSettings, masks: { input: 'MMM D, YYYY' }, showPassengerDropdown, showAirlinesModal, debug, travellerType }
  },
  mounted () {
    this.clearItineraries()
    this.setupFormFromParams()
  },
  methods: {
    ...mapMutations(['updateItineraryTrees', 'updateItineraries', 'updateFeatureMinMaxes', 'updateAirlineCodes',
      'showSpinner', 'hideSpinner', 'toggleAirlinesModal', 'updateSelectedAirlineCodes',
      'updateExcludedAirlineCodes', 'showNoItineraries', 'hideNoItineraries', 'incrementApiLoad', 'setupTimeout',
      'clearTimeout', 'updateOItineraries', 'clearItineraries']),
    ...mapGetters(['itinerariesPresent', 'getExcludedAirlines', 'getSelectedAirlines']),
    setupFormFromParams () {
      const route = useRoute()
      if (!route.query.from_airport) {
        return
      }

      if (route.query.from_airport) {
        this.query.outbound.airport = [route.query.from_airport] as string[]
        (this.$refs.outboundAirportSelect as any).select2.append('<option selected value="' + route.query.from_airport + '">' + _.split(route.query.from_airport as string, '**')[0] + '</option>').trigger('change')
      }

      if (route.query.return_airport) {
        this.query.return.airport = [route.query.return_airport] as string[]
        (this.$refs.returnAirportSelect as any).select2.append('<option selected value="' + route.query.return_airport + '">' + _.split(route.query.return_airport as string, '**')[0] + '</option>').trigger('change')
      }

      this.query.oneWay = route.query.one_way === 'true'

      if (route.query.from_date) {
        this.query.outbound.date = dayjs(route.query.from_date as string, 'DD-MMM-YYYY', 'en').toDate()
      }

      if (route.query.return_date) {
        this.query.return.date = dayjs(route.query.return_date as string, 'DD-MMM-YYYY', true).toDate()
      }

      if (route.query.from_time) {
        this.query.outbound.time = route.query.from_time as string
      }

      if (route.query.return_time) {
        this.query.return.time = route.query.return_time as string
      }

      if (route.query.adults) {
        this.query.passengers.adults = parseInt(route.query.adults as string)
      }

      if (route.query.children) {
        this.query.passengers.children = parseInt(route.query.children as string)
      }

      if (route.query.infants) {
        this.query.passengers.infants = parseInt(route.query.infants as string)
      }
      this.submitForm(false)
    },
    updateURLWithParams () {
      const params = {} as any

      if (this.query.oneWay) {
        params.one_way = this.query.oneWay
      }

      if (this.query.outbound.airport) {
        params.from_airport = this.query.outbound.airport
      }

      if (this.query.return.airport) {
        params.return_airport = this.query.return.airport
      }

      if (this.query.outbound.date) {
        params.from_date = dayjs(this.query.outbound.date).format('DD-MMM-YYYY')
      }

      if (this.query.return.date) {
        params.return_date = dayjs(this.query.return.date).format('DD-MMM-YYYY')
      }

      if (this.query.outbound.time) {
        params.from_time = this.query.outbound.time
      }

      if (this.query.return.time) {
        params.return_time = this.query.return.time
      }

      if (this.query.passengers.adults) {
        params.adults = this.query.passengers.adults
      }

      if (this.query.passengers.children) {
        params.children = this.query.passengers.children
      }

      if (this.query.passengers.infants) {
        params.infants = this.query.passengers.infants
      }

      this.$router.push({ path: 'search', query: params })
    },
    handleDates (body: any) {
      const DATE_FORMAT = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)((-(\d{2}):(\d{2})|Z)?)$/

      if (body === null || body === undefined || typeof body !== 'object') {
        return body
      }

      for (const key of Object.keys(body)) {
        const value = body[key]
        if (value && typeof value === 'string' && DATE_FORMAT.test(value)) {
          body[key] = new Date(Date.parse(value))
        } else if (typeof value === 'object') {
          this.handleDates(value)
        }
      }
    },
    validateForm () {
      if (!this.errors.submitClicked) { return true }

      let valid = true

      if (this.query.outbound.airport.length) {
        this.errors.outbound.airport = ''
      } else {
        this.errors.outbound.airport = 'Please select airport'
        valid = false
      }

      if (this.query.return.airport.length) {
        this.errors.return.airport = ''
      } else {
        this.errors.return.airport = 'Please select airport'
        valid = false
      }

      return valid
    },
    submitForm (includeAirlines : boolean) {
      this.errors.submitClicked = true
      this.clearTimeout()

      if (!this.validateForm()) { return }

      this.axios.interceptors.response.use((response) => {
        this.handleDates(response.data)
        return response
      })

      const queryData = {
        from_airports: this.query.outbound.airport,
        from_date: dayjs(this.query.outbound.date).format('DD MMM YYYY'),
        from_time: this.query.outbound.time,
        to_airports: this.query.return.airport,
        return_date: dayjs(this.query.return.date).format('DD MMM YYYY'),
        return_time: this.query.return.time,
        passengers: this.query.passengers,
        one_way: this.query.oneWay,
        selected_airlines: [],
        excluded_airlines: [],
        debug: this.debug,
        traveller_type: this.travellerType
      }

      if (includeAirlines) {
        queryData.selected_airlines = this.getSelectedAirlines()
        queryData.excluded_airlines = this.getExcludedAirlines()
      } else {
        this.updateSelectedAirlineCodes([])
        this.updateExcludedAirlineCodes([])
      }

      this.showSpinner()
      this.axios.post(process.env.VUE_APP_API_BASE_PATH + '/find-itineraries', queryData).then(response => {
        if (response.data.itineraries) {
          this.updateItineraryTrees(response.data.itineraryTrees)
          this.updateItineraries(response.data.itineraries)
          this.updateOItineraries(response.data.oItineraries)
          this.updateFeatureMinMaxes(response.data.featureMinMaxes)
          this.updateAirlineCodes(response.data.airlineCodes)
          this.hideNoItineraries()
        } else {
          this.showNoItineraries()
        }
        this.hideSpinner()
        this.incrementApiLoad()
        this.setupTimeout()
        this.updateURLWithParams()
      }).catch(error => {
        alert(error)
        this.hideSpinner()
      })
    },
    resetForm: function () {
      Object.assign(this.$data, initialState())
    },
    minusAdults () {
      if (this.query.passengers.adults > 0) {
        this.query.passengers.adults -= 1
      }
      this.ensureAlwaysPassengers()
    },
    plusAdults () {
      this.query.passengers.adults += 1
    },
    minusChildren () {
      if (this.query.passengers.children > 0) {
        this.query.passengers.children -= 1
      }
      this.ensureAlwaysPassengers()
    },
    plusChildren () {
      this.query.passengers.children += 1
    },
    minusInfants () {
      if (this.query.passengers.infants > 0) {
        this.query.passengers.infants -= 1
      }
      this.ensureAlwaysPassengers()
    },
    plusInfants () {
      if (this.query.passengers.adults > this.query.passengers.infants) {
        this.query.passengers.infants += 1
      } else {
        alert("We can't provide itineraries with more infants than adults")
      }
    },
    ensureAlwaysPassengers () {
      if (this.query.passengers.adults === 0 &&
        this.query.passengers.children === 0 &&
        this.query.passengers.infants === 0) {
        this.query.passengers.adults = 1
      }
    },
    hidePassengerDropdown (e : any) {
      if (e.target.id !== 'passenger-form-control') {
        this.showPassengerDropdown = false
      }
    }
  }
})
