<template>
  <Header @resetForm="resetForm" />
  <router-view v-slot="{ Component }" >
    <component ref="view" :is="Component" />
  </router-view>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue'
import 'bootstrap'
import 'select2/dist/css/select2.min.css'
import 'select2-bootstrap-5-theme/dist/select2-bootstrap-5-theme.min.css'
import ItineraryStructure from '@/components/ItineraryStructure.vue'
import FavoritesModal from '@/components/FavoritesModal.vue'
import TopForm from '@/components/TopForm.vue'
import { useStore } from '@/store'
import { mapGetters, mapMutations } from 'vuex'
import MinMaxRows from '@/components/MinMaxRows.vue'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import AllItineraries from '@/components/AllItineraries.vue'
import ItineraryPage from '@/components/ItineraryPage.vue'
import Chatbot from '@/components/Chatbot.vue'

export default defineComponent({
  name: 'App',
  components: {
    Footer,
    Header
  },
  methods: {
    resetForm () {
      (this.$refs.view as typeof ItineraryPage).resetForm()
    }
  }
})
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Amaranth:ital,wght@0,400;0,700;1,400;1,700&display=swap');

html, body {
  font-family: 'Roboto', sans-serif;
}

#app {
  font-family: 'Roboto', sans-serif;
}

.fancy-font {
  font-family: 'Amaranth', sans-serif;
}

@import './assets/css/bootstrap-theme.scss';
@import '../node_modules/bootstrap/scss/bootstrap.scss';

.fill-primary {
  fill: $primary;
}

.fill-secondary {
  fill: $secondary;
}

.text-gray {
  color: $gray-600;
}

.bg-gray {
  background-color: $gray-300;
}

.v-tour__target--highlighted {
  box-shadow: 0 0 0 4px $secondary !important;
}

.v-step__header, .v-step__arrow:before {
  background-color: $primary !important;
  font-size: 18px !important;
}

.v-step {
  background-color: $gray-600 !important;
  max-width: 500px !important;
  font-size: 17px !important;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 90%) !important;
}

.v-step__button {
  padding: 10px !important;
  border-radius: 0.25rem !important;
  margin-left: 15px !important;
  height: 100% !important;
  width: 100px !important;
  background-color: $secondary !important;
  border-color: $gray-300 !important;
  font-weight: bold !important;
  font-size: 14px !important;
}

.v-step__button-skip, .v-step__button-previous {
  background-color: #FFFFFF !important;
  border-color: $gray-100 !important;
  color: $gray-600 !important;
}

</style>
<style>

.cursor-pointer {
  cursor: pointer;
}

.text-black {
  color: #000000 !important;
}

.text-light {
  color: #F2F2F2 !important;
}

.min-vh-50 {
  min-height: 50vh !important;
}

.min-vh-80 {
  min-height: 80vh !important;
}

.border-dark {
  border-color: darkgray !important;
}

</style>
