
import { computed, defineComponent, ref } from 'vue'
import { useStore } from '@/store'
import { mapGetters, mapMutations } from 'vuex'
import airlineNames from './../assets/airlines.json'
import _ from 'lodash'

export default defineComponent({
  name: 'AirlineSelector',
  emits: ['searchWithUpdatedAirlines'],
  setup () {
    const store = useStore()
    const allAirlinesCodes = ref(_.sortBy(_.uniq(store.state.airlineCodes.concat(store.state.excludedAirlineCodes))))
    const airlineCodesFromAPI = ref(store.state.airlineCodes)
    const allianceCodes : {[index: string]: string[]} = {
      'Star Alliance': ['A3', 'AC', 'CA', 'AI', 'NZ', 'NH', 'OZ', 'OS', 'AV', 'SN', 'CM', 'OU', 'MS', 'BR', 'ET', 'LO', 'LH', 'SK', 'ZH', 'SQ', 'SA', 'LX', 'TP', 'TG', 'TK', 'UA'],
      SkyTeam: ['SU', 'AR', 'AM', 'UX', 'AF', 'AZ', 'CI', 'MU', 'OK', 'DL', 'KQ', 'KL', 'KE', 'ME', 'SV', 'RO', 'VN', 'MF', 'GA'],
      OneWorld: ['AA', 'BA', 'CX', 'AY', 'IB', 'JL', 'MH', 'QF', 'QR', 'RJ', 'S7', 'UL', 'AT']
    }
    const allianceAnyCode = _.flatten(_.values(allianceCodes))
    return { allAirlinesCodes, airlineCodesFromAPI, allianceCodes, allianceAnyCode, airlineNames }
  },
  data () {
    return {
      selectedAirlines: [] as string[]
    }
  },
  mounted () {
    this.selectedAirlines = _.difference(this.airlineCodesFromAPI, this.getExcludedAirlines())
  },
  computed: {
    excludedAirlines () : string[] {
      return _.difference(this.allAirlinesCodes, this.selectedAirlines)
    }
  },
  methods: {
    ...mapMutations(['toggleAirlinesModal', 'updateSelectedAirlineCodes', 'updateExcludedAirlineCodes']),
    ...mapGetters(['returnPresent', 'getExcludedAirlines']),
    updateAirlinesInStore () {
      this.updateSelectedAirlineCodes(this.selectedAirlines)
      this.updateExcludedAirlineCodes(this.excludedAirlines)
    },
    updateAirlinesClicked () {
      this.$emit('searchWithUpdatedAirlines')
      this.toggleAirlinesModal()
    },
    selectAll (allianceName : string) {
      this.selectedAirlines = _.uniq(_.concat(this.selectedAirlines, this.allianceCodes[allianceName]))
      this.updateAirlinesInStore()
    },
    deselectAll (allianceName : string) {
      this.selectedAirlines = _.difference(this.selectedAirlines, this.allianceCodes[allianceName])
      this.updateAirlinesInStore()
    }
  }
})
