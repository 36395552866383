<template>
  <div class="container min-vh-80 pb-5">

    <div class="col-sm-12 col-md-10 col-xl-8 pt-3">
      <h2 class="p-2 text-center">Reagent Flight Booking Bot</h2>
      <p class="ms-3 text-center">To try out our chat bot click the orange chat button in the bottom right, or call <a href="tel:+16503384212">+1 650-338-4212</a></p>
      <p class="ms-3 text-center">Choose traveller type:
        <input class="ms-3" type="radio" id="budget" name="traveller_type" @change="setTraveller('budget')" />
        <label class="px-1" for="budget">Budget</label>

        <input class="ms-3" type="radio" id="standard" name="traveller_type" @change="setTraveller('standard')" />
        <label class="px-1" for="standard">Standard</label>

        <input class="ms-3" type="radio" id="road_warrior" name="traveller_type" @change="setTraveller('road_warrior')" checked="checked" />
        <label class="px-1" for="road_warrior">Road Warrior</label>

        <input class="ms-3" type="radio" id="vip" name="traveller_type" @change="setTraveller('vip')" />
        <label class="px-1" for="vip">VIP</label>
      </p>

      <div class="m-1 m-sm-3 rounded-3 px-4 px-sm-5 py-3" style="background-color: #F9F9F9">
        <h3 class="text-center pb-1 pb-sm-4">Example conversations</h3>

        <div class="accordion" id="accordionExample">
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingOne">
              <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                Typical business traveller
              </button>
            </h2>
            <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <div class="chat-wrapper">
                  <p class="receive">Hi Tim, tell me about your trip.</p>
                  <p class="send">I'm headed to Denver on Monday. I need to be there by 11.</p>
                  <p class="receive">Heading to Denver Monday May 2nd, ok. <br /> Where are you leaving from?</p>
                  <p class="send">SFO</p>
                  <p class="receive">And what date are you coming back?</p>
                  <p class="send">I need to be there for 3 nights.</p>
                  <p class="receive">And you wanted to arrive before 11:00am on the way there, correct?</p>
                  <p class="send">yes</p>
                  <p class="receive">And any time preference for the return flight?</p>
                  <p class="send">I need to be there until noon</p>
                  <p class="receive">Alright, getting to Denver before 11:00am Monday May 2nd and coming back to San Francisco on a flight leaving after 12:00pm on Thursday May 5th. Good?</p>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingTwo">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                Wordy business traveller
              </button>
            </h2>
            <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <div class="chat-wrapper">
                  <p class="receive">Hey Andrew, tell me about your trip.</p>
                  <p class="send">I need to be in London on Wednesday by noon for a super important meeting.</p>
                  <p class="receive">Heading to London Wednesday the 27th, got it.<br />Where are you leaving from?</p>
                  <p class="send">I'm flying out of Brussels in Belgium.</p>
                  <p class="receive">And what date are you coming back?</p>
                  <p class="send">Oh, I just need to be there that day.</p>
                  <p class="receive">And you wanted to arrive before noon on the way there, right?</p>
                  <p class="send">Yes, that's critical!</p>
                  <p class="receive">And any time preference for the return flight?</p>
                  <p class="send">It would be great if I could leave just after business hours.</p>
                  <p class="receive">Ok, getting to London before 12:00pm Wednesday the 27th and coming back to Brussels on a flight leaving after business hours Wednesday the 27th. Correct?</p>
                  <p class="send">Yes that is correct</p>
                  <p class="receive">Now, what's more important for this trip? The price, the length of travel, or when you'll arrive and leave?</p>
                  <p class="send">The time I get there.</p>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingThree">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                Business traveller succinct
              </button>
            </h2>
            <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <div class="chat-wrapper">
                  <p class="receive">Hi Brian, tell me about your trip.</p>
                  <p class="send">LAX DEN fri</p>
                  <p class="receive">Los Angeles to Denver Friday the 29th, got it. <br /> And what date are you coming back?</p>
                  <p class="send">mon</p>
                  <p class="receive">Got any time preference for the outbound flight?</p>
                  <p class="send">8</p>
                  <p class="receive">And any time preference for the return flight?</p>
                  <p class="send">after 1</p>
                  <p class="receive">Alright, departing Los Angeles Friday the 29th around 8:00am and returning from Denver on Monday May 2nd on a flight that leaves after 1:00pm. Correct?</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-4">
    <!--    Holder for chat on desktop.-->
  </div>
  <Footer/>
</template>
<script lang="ts">
import { computed, defineComponent, ref } from 'vue'
import { store, useStore } from '@/store'
import _round from 'lodash/round'
import { mapGetters, mapMutations } from 'vuex'
import TopForm from '@/components/TopForm.vue'
import Footer from '@/components/Footer.vue'
import AllItineraries from '@/components/AllItineraries.vue'
import ItineraryStructure from '@/components/ItineraryStructure.vue'
import ItineraryFooter from '@/components/ItineraryFooter.vue'
import Bugsnag from '@bugsnag/js'
import app from '@/App.vue'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'DemoPage',
  components: { Footer },
  methods: {
    setTraveller (travellerType : string) {
      // eslint-disable-next-line no-undef
      Botcopy.setCXParameters({
        payload: {
          traveller_type: { stringValue: travellerType }
        }
      })
    }
  }
})
</script>

<style lang="scss" scoped>

  .accordion-item {
    border: 1px solid rgba(0, 0, 0, 0.425);
    border-radius: 10px;
    margin: 5px 0px 15px 0px;
  }

  .accordion-header {
    border-radius: 10px;
  }

  .accordion-button {
    background-color: #EFEFEF;
    border-radius: 10px !important;
    overflow: hidden;
    color: #000000;
    font-weight: bold;
    font-size: 18px;
  }

  .chat-wrapper {
    display: flex;
    flex-direction: column;

    p {
      word-wrap: break-word;
      line-height: 18px;
      position: relative;
      padding: 10px 20px;
      border-radius: 25px;
      margin: 0px 10px 7px 10px;

      &:before, &:after {
        content: "";
        position: absolute;
        bottom: 0;
        height: 25px;
      }
    }

    .send {
      color: #FFFFFF;
      background: #0B93F6;
      align-self: flex-end;

      &:before {
        right: -7px;
        width: 20px;
        background-color: #0B93F6;
        border-bottom-left-radius: 16px 14px;
      }

      &:after {
        right: -25px;
        width: 26px;
        background-color: #FFFFFF;
        border-bottom-left-radius: 10px;
      }
    }

    .receive {
      background: #E9ECEF;
      color: black;
      align-self: flex-start;

      &:before {
        left: -7px;
        width: 20px;
        background-color: #e9ecef;
        border-bottom-right-radius: 16px 14px;
      }

      &:after {
        left: -26px;
        width: 26px;
        background-color: #FFFFFF;
        border-bottom-right-radius: 10px;
      }
    }
  }
</style>
