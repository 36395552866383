<template>
  <template v-if="showAllItineraries">
    <div class="row d-flex" style="padding-left: 15px" >
      <div class="cursor-pointer" @click="showAllItineraries = !showAllItineraries">
        <font-awesome-icon icon="caret-down" size="lg" />
        Hide All {{sortedItineraries.length}} Itineraries
      </div>

      <div class="row d-flex text-center" style="padding-left: 15px" >
        <div class="col-2"></div>
        <div class="heading col d-flex justify-content-evenly" @click="sort('price')">
          <div>
            Price
          </div>
          <div class="position-relative ps-2">
            <font-awesome-icon :icon="sortArrow" size="lg" v-if="sortAttribute==='price'" class="text-black" style="position: absolute; right: 0px" />
            <font-awesome-icon :icon="oppositeArrow" size="lg" v-if="sortAttribute==='price'" class="text-info" style="position: absolute; right: 0px" />
            <font-awesome-icon icon="sort" size="lg" class="text-info" v-else style="position: absolute; right: 0px" />
          </div>
        </div>
        <div class="heading col d-flex justify-content-evenly" @click="sort('durationAverage')">
          <div>
            Duration
          </div>
          <div class="position-relative ps-2">
            <font-awesome-icon :icon="sortArrow" size="lg" v-if="sortAttribute==='durationAverage'" class="text-black" style="position: absolute; right: 0px" />
            <font-awesome-icon :icon="oppositeArrow" size="lg" v-if="sortAttribute==='durationAverage'" class="text-info" style="position: absolute; right: 0px" />
            <font-awesome-icon icon="sort" size="lg" class="text-info" v-else style="position: absolute; right: 0px" />
          </div>
        </div>
        <div class="heading col d-flex justify-content-evenly" @click="sort('totalStops')">
          <div>
            Stops
          </div>
          <div class="position-relative  ps-2">
            <font-awesome-icon :icon="sortArrow" size="lg" v-if="sortAttribute==='totalStops'" class="text-black" style="position: absolute; right: 0px" />
            <font-awesome-icon :icon="oppositeArrow" size="lg" v-if="sortAttribute==='totalStops'" class="text-info" style="position: absolute; right: 0px" />
            <font-awesome-icon icon="sort" size="lg" class="text-info" v-else style="position: absolute; right: 0px" />
          </div>
        </div>
        <div class="heading col-3"> Outbound Times </div>
        <div class="heading col-3" v-if="returnPresent()"> Return Times </div>
        <div class="col"></div>
      </div>
      <div class="large-container bg-white rounded border-2 container border pe-0">
        <RecycleScroller class="scroller" :items="sortedItineraries" :item-size="69" :key="scrollingKey">
          <template v-slot="{ item }">
            <ItineraryRow :plainItinerary="item" :showDescriptionRow="true" :key="item.id" />
          </template>
        </RecycleScroller>
      </div>
    </div>
  </template>
  <template v-else>
    <div class="row cursor-pointer" style="padding-left: 15px" >
      <div class="col" @click="showAllItineraries = !showAllItineraries">
        <font-awesome-icon icon="caret-right" size="lg" />
        Show All {{sortedItineraries.length}} Itineraries
      </div>
      <div class="col text-end">
        <router-link to="/our-approach" class="text-dark" target="_blank">Why so few results?</router-link>
      </div>
    </div>
  </template>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from 'vue'
import Itinerary from '@/types/Itinerary'
import ItineraryRow from '@/components/ItineraryRow.vue'
import _ from 'lodash'
import { mapGetters } from 'vuex'

export default defineComponent({
  name: 'AllItineraries',
  components: { ItineraryRow },
  props: {
    itineraries: { type: Object as PropType<Itinerary[]>, required: false }
  },
  data () {
    const sortAttribute = 'price'
    const sortDescending = false
    const scrollingKey = 0

    return { sortAttribute, sortDescending, scrollingKey }
  },
  computed: {
    sortedItineraries () : Itinerary[] {
      const sortAttribute = this.sortAttribute
      const sortDirection = this.sortDescending ? 'desc' : 'asc'
      return _.orderBy(this.$props.itineraries, [sortAttribute, 'price', 'averageDuration'], [sortDirection, 'asc', 'asc'])
    },
    sortArrow () : string {
      return this.sortDescending ? 'sort-up' : 'sort-down'
    },
    oppositeArrow () : string {
      return this.sortDescending ? 'sort-down' : 'sort-up'
    }
  },
  setup () {
    const showAllItineraries = ref(false)
    return { showAllItineraries }
  },
  methods: {
    ...mapGetters(['returnPresent']),
    sort (attribute : string) {
      if (this.sortAttribute === attribute) {
        this.sortDescending = !this.sortDescending
      }
      this.sortAttribute = attribute
      this.scrollingKey += 1
    }
  }
})
</script>

<style scoped>
.heading {
  font-size: 12px;
}

.large-container {
  border-color: darkgray !important;
}

.scroller {
  height: 500px;
}
</style>
