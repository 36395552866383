
import { defineComponent, PropType, ref } from 'vue'
import Itinerary from '@/types/Itinerary'
import ItineraryRow from '@/components/ItineraryRow.vue'
import _ from 'lodash'
import { mapGetters } from 'vuex'

export default defineComponent({
  name: 'AllItineraries',
  components: { ItineraryRow },
  props: {
    itineraries: { type: Object as PropType<Itinerary[]>, required: false }
  },
  data () {
    const sortAttribute = 'price'
    const sortDescending = false
    const scrollingKey = 0

    return { sortAttribute, sortDescending, scrollingKey }
  },
  computed: {
    sortedItineraries () : Itinerary[] {
      const sortAttribute = this.sortAttribute
      const sortDirection = this.sortDescending ? 'desc' : 'asc'
      return _.orderBy(this.$props.itineraries, [sortAttribute, 'price', 'averageDuration'], [sortDirection, 'asc', 'asc'])
    },
    sortArrow () : string {
      return this.sortDescending ? 'sort-up' : 'sort-down'
    },
    oppositeArrow () : string {
      return this.sortDescending ? 'sort-down' : 'sort-up'
    }
  },
  setup () {
    const showAllItineraries = ref(false)
    return { showAllItineraries }
  },
  methods: {
    ...mapGetters(['returnPresent']),
    sort (attribute : string) {
      if (this.sortAttribute === attribute) {
        this.sortDescending = !this.sortDescending
      }
      this.sortAttribute = attribute
      this.scrollingKey += 1
    }
  }
})
