<template>
  <div class="container min-vh-80">
    <div class="row">
      <div class="col">
        <h2 class="p-2 text-center">About Us</h2>
      </div>
    </div>
    <div class="row">
      <div class="col-6 col-sm-9">
        <p>Greetings from Brian and Andrew, founders of Reagent!</p>
        <p>
          We concocted Reagent because we’re avid travellers that hate searching for travel.<br />
          We love the travel part, just not the search/compare/booking rigamarole that goes on and on with no clear endpoint.
        </p>
        <figure class="bg-gray rounded m-3 p-4">
          <blockquote class="blockquote fw-bold" style="font-size: 16px">
            <p>Part of the elure of a trip is the anticipation, and I would rather spend time dreaming about what I’m going to do at the destination, not endlessly searching for how I’m going to get there.</p>
          </blockquote>
          <figcaption class="blockquote-footer mb-0">
            Brian
          </figcaption>
        </figure>
      </div>
      <div class="col-6 col-sm-3 text-center">
        <img src="../assets/about-us/brian.png" style="max-width: 200px; width: 80%" class="rounded img-fluid" />
      </div>
    </div>
    <div class="row">
      <div class="col">
        <p>
          The Reagent journey began by evaluating how humans make decisions: how we compare, weigh, and eliminate options until there’s just one left. And that’s where we hit our first epiphone <span class="text-secondary">—with existing searches, there is no endpoint!</span> You can forever open new browser tabs, slightly modify parameters, and scan through endless options, just to effectively end up where you started. Sure, sometimes you hit the jackpot and find that once-in-a-lifetime flight that saves you hundreds of dollars—but how much time did it take to get there?
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-6 col-sm-3 text-center">
        <img src="../assets/about-us/andrew.jpg" style="max-width: 200px; width: 80%" class="rounded img-fluid" />
      </div>
      <div class="col-6 col-sm-9">
        <p>
          Of course some subscription services push incredible deals (which if you're flexible for your next adventure are amazing!) but most people don't have the freedom to jump on a crazy cheap flight next week just because it's available.
        </p>
        <figure class="bg-gray rounded m-3 p-4">
          <blockquote class="blockquote fw-bold" style="font-size: 16px">
            <p>
              Usually you just need to find the best flight that fits your terms.
            </p>
          </blockquote>
          <figcaption class="blockquote-footer mb-0">
            Andrew
          </figcaption>
        </figure>
        <p>
          We set out to get you as close to this as possible <i>in as little time as possible.</i>*<br />
          We told a computer how to evaluate things in a similar way to you so that your focus can be on just the sensible flight options.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <p>
          Of course everyone has slightly different desires and requirements, so we can’t do all the work. We give you overall best-value starting points and then let you walk down each tree, seeing similar/next-best options until you reach the absolute cheapest/fastest/most timely option available.
        </p>
        <p class="text-secondary">
          The overall result is that you get to review just the options that make sense, while actually considering every option in the search results!
        </p>
        <p>
          Don’t trust us? We left all the data in a complete list at the bottom of the search page.<br />
          We also welcome <router-link to="/contact">feedback</router-link> as we are lifelong learners!
        </p>
        <p>
          At the end of the day, we don’t want to give you just another flight search, <span class="text-secondary">we want to empower you with a completely new way to search.</span>
        </p>
        <p>
          While everyone else is wrestling to build a better oil rig, we’re seeing first light on our fusion reactor. Join us in the search <i>revolution</i>.
        </p>
        <p class="fw-bold pb-5">
          Happy smarter searching!<br />
          ~The Reagent Team
        </p>
      </div>
    </div>

  </div>
  <Footer />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Footer from '@/components/Footer.vue'

export default defineComponent({
  name: 'AboutPage',
  components: {
    Footer
  }
})
</script>

<style scoped>
</style>
