<template>
  <div class="modal modal-active" tabindex="-1">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title w-100 text-center">
            Compare
            <font-awesome-icon icon="heart" />
            Itineraries
          </h5>
          <button @click="toggleFavoritesModal()" type="button" class="btn-close" />
        </div>
        <div class="modal-body">
          <div v-if="favoriteItineraries().length > 0">
            <div class="row d-flex text-center" style="padding-left: 15px" >
              <div class="heading col"> Price </div>
              <div class="heading col"> Duration </div>
              <div class="heading col"> Stops </div>
              <div class="heading col-3 px-2"> Outbound Times </div>
              <div class="heading col-3 px-2" v-if="returnPresent()"> Return Times </div>
              <div class="col"></div>
            </div>
            <div v-for="itinerary in favoriteItineraries()" :key="itinerary.id">
              <ItineraryRow :plainItinerary="itinerary" :showDescriptionRow="false" :showFavouriteRemove="true" />
            </div>
          </div>
          <div v-else>
            No itineraries selected. Click the heart icon to add itineraries you are considering.
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-backdrop show"></div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapGetters, mapMutations } from 'vuex'
import ItineraryRow from '@/components/ItineraryRow.vue'

export default defineComponent({
  name: 'FavoritesModal',
  components: {
    ItineraryRow
  },
  methods: {
    ...mapMutations(['toggleFavoritesModal']),
    ...mapGetters(['favoriteItineraries', 'returnPresent'])
  }
})

</script>

<style scoped>
.modal-active{
  display:block;
}

.modal-dialog{
  position: relative;
  display: table; /* This is important */
  overflow-y: auto;
  overflow-x: auto;
  width: auto;
  min-width: 1000px;
}

.heading {
  font-size: 12px;
}

</style>
