<template>
  <div class="container min-vh-80">
    <h2 class="p-2 text-center">Terms & Conditions</h2>
    <h4>Disclaimer of Warranties/Accuracy and Use of Data</h4>
    <p>
      While much effort has gone into ensuring the data on this site is accurate, errors might still exist. All information provided is done so on an "AS IS" basis for personal use only and may not be reproduced in any form, in whole or in part. No warranty expressed or implied is made regarding accuracy, currency, adequacy, completeness, reliability or usefulness of any information. If you find any errors or omissions, please report them to us by emailing <a href="mailto:hello@reagent.travel">hello@reagent.travel</a>.
    </p>

    <h4>Disclaimer of Endorsement</h4>
    <p>
      Reference herein to any specific products or service by trade name, trademark, manufacturer, or otherwise, does not constitute or imply its endorsement, recommendation, or favoring by reagent.travel, and such reference shall not be used for advertising or product endorsement purposes. The data provided is done so for the express purpose of assisting with consumer purchase decisions and is not intended to be used in any other manner.
    </p>
  </div>
  <Footer />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Footer from '@/components/Footer.vue'

export default defineComponent({
  name: 'AboutPage',
  components: {
    Footer
  }
})
</script>

<style scoped>
</style>
