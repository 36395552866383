import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'
import { store, key } from './store'

import Plausible from 'plausible-tracker'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue, { BugsnagPluginVueResult } from '@bugsnag/plugin-vue'
import VueTour from 'v3-tour'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import VueVirtualScroller from 'vue-virtual-scroller'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'

import { library } from '@fortawesome/fontawesome-svg-core'

import VueElementLoading from 'vue-element-loading'

import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Popper from 'vue3-popper'
import './assets/css/popper.css' // Magic happens here

import { faClipboard, faArrowRight, faArrowLeft, faCaretRight, faCaretDown, faHeart, faCircle, faPlaneDeparture, faPlaneArrival, faMountain, faTrophy, faInfoCircle, faSortDown, faSortUp, faSort } from '@fortawesome/free-solid-svg-icons'
import { faHeart as farHeart, faPlusSquare as farPlusSquare, faMinusSquare as farMinusSquare } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Select2 from 'vue3-select2-component'
import { DatePicker } from 'v-calendar'
import VueClickAway from 'vue3-click-away'

import axios from 'axios'
import VueAxios from 'vue-axios'

import App from './App.vue'
import { createRouter, createWebHistory } from 'vue-router'
import ItineraryPage from '@/components/ItineraryPage.vue'
import AboutPage from '@/components/AboutPage.vue'
import TermsAndConditionsPage from '@/components/TermsAndConditionsPage.vue'
import OurApproachPage from '@/components/OurApproachPage.vue'
import VoicePage from '@/components/VoicePage.vue'
import AffiliatePolicyPage from '@/components/AffiliatePolicyPage.vue'
import ContactUsPage from '@/components/ContactUsPage.vue'
import EmailSignupPage from '@/components/EmailSignupPage.vue'
import DemoPage from '@/components/DemoPage.vue'
dayjs.extend(customParseFormat)
require('v3-tour/dist/vue-tour.css')

const { trackPageview } = Plausible()
const { enableAutoPageviews } = Plausible({
  trackLocalhost: true
})
trackPageview()
enableAutoPageviews()

Bugsnag.start({
  apiKey: '2bdd9b930badf5088d5d46ddbdb4262d',
  plugins: [new BugsnagPluginVue()],
  enabledReleaseStages: ['production', 'staging']
})

const bugsnagVue = Bugsnag.getPlugin('vue') as BugsnagPluginVueResult

library.add(faClipboard, faArrowRight, faArrowLeft, faCaretRight, faCaretDown, faHeart, farHeart, faCircle, faInfoCircle)
library.add(faPlaneDeparture, faPlaneArrival, faMountain, faTrophy, farPlusSquare, farMinusSquare, faSortUp, faSortDown, faSort)

const i18n = createI18n({
  legacy: false,
  locale: 'en',
  globalInjection: true,
  messages: {
    en: {
      itinerary: {
        stop: 'stop | stops'
      }
    }
  }
})

const routes = [
  {
    path: '/',
    name: 'Flight booking chat & voice bot',
    component: DemoPage
  },
  {
    path: '/search',
    name: 'Itineraries',
    component: ItineraryPage
  },
  {
    path: '/our-approach',
    name: 'Our Approach',
    component: OurApproachPage
  },
  {
    path: '/voice',
    name: 'Voice',
    component: VoicePage
  },
  {
    path: '/about',
    name: 'About',
    component: AboutPage
  },
  {
    path: '/terms-and-conditions',
    name: 'Terms & Conditions',
    component: TermsAndConditionsPage
  },
  {
    path: '/affiliate-policy',
    name: 'Affiliate Policy',
    component: AffiliatePolicyPage
  },
  {
    path: '/contact',
    name: 'Contact Us Policy',
    component: ContactUsPage
  },
  {
    path: '/email-signup',
    name: 'Email Signup',
    component: EmailSignupPage
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

createApp(App)
  .use(bugsnagVue)
  .use(store, key)
  .use(i18n)
  .use(VueAxios, axios)
  .use(VueClickAway)
  .use(router)
  .use(VueVirtualScroller)
  .use(VueTour)
  .component('Plausible', Plausible)
  .component('Popper', Popper)
  .component('VueElementLoading', VueElementLoading)
  .component('font-awesome-icon', FontAwesomeIcon)
  .component('Select2', Select2)
  .component('DatePicker', DatePicker)
  .mount('#app')
