<template>
  <div class="container min-vh-80">
    <h2 class="p-2 text-center">Voice Demo</h2>
    <h4>IVR, Smart Assistant & Chat bot applications</h4>
    <p>Our core technology helps find only the flights that should be considered, this makes it a perfect fit for a voice interface. Here is example of what our voice forward product is capable of:</p>
      <div class="show-lg d-none d-xl-block">
        <iframe width="672" height="378" src="https://www.youtube.com/embed/DhsInC6vLJc" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
      <div class="video-responsive d-xl-none">
        <iframe width="672" height="378" src="https://www.youtube.com/embed/DhsInC6vLJc" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
    <br />
    <h4>Contact Us</h4>
    <p>If you are interested in partnering with us or just finding out more about our technology send us an email at <a href="mailto:hello@reagent.travel">hello@reagent.travel</a></p>
    <p>&nbsp;</p>
  </div>
  <Footer />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Footer from '@/components/Footer.vue'

export default defineComponent({
  name: 'VoicePage',
  components: {
    Footer
  }
})
</script>

<style scoped>
.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position:absolute;
}

</style>
