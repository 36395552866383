<template>
  <footer v-if="itinerariesPresent()" class="footer bg-white fixed-bottom border-top border-dark" style="height: 60px">
    <div class="container">
      <div class="container">
        <div class="row p-2 d-flex align-items-center">
          <MinValues />
          <div class="col-3 px-2"></div>
          <div class="col-3 px-2" v-if="returnPresent()"></div>
          <div class="col px-2 text-end">
            <div style="margin-left: -50px">
              <button @click="toggleFavoritesModal()" class="btn btn-secondary py-2 px-1" style="font-size: 14px; color: white" >
                <font-awesome-icon icon="heart" />
                Compare
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
  <FavoritesModal v-if="showFavouritesModal" />
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import { useStore } from '@/store'
import { mapGetters, mapMutations } from 'vuex'
import FavoritesModal from '@/components/FavoritesModal.vue'
import MinValues from '@/components/MinValues.vue'

export default defineComponent({
  name: 'ItineraryFooter',
  components: {
    FavoritesModal,
    MinValues
  },
  setup () {
    const store = useStore()

    const showFavouritesModal = computed(() => store.state.showFavoritesModal)
    return { showFavouritesModal }
  },
  methods: {
    ...mapMutations(['toggleFavoritesModal']),
    ...mapGetters(['favoriteItineraries', 'itinerariesPresent', 'returnPresent'])
  }
})
</script>

<style scoped>

</style>
