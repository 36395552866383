
import { computed, defineComponent, ref } from 'vue'
import 'bootstrap'
import 'select2/dist/css/select2.min.css'
import 'select2-bootstrap-5-theme/dist/select2-bootstrap-5-theme.min.css'
import ItineraryStructure from '@/components/ItineraryStructure.vue'
import FavoritesModal from '@/components/FavoritesModal.vue'
import TopForm from '@/components/TopForm.vue'
import { useStore } from '@/store'
import { mapGetters, mapMutations } from 'vuex'
import MinMaxRows from '@/components/MinMaxRows.vue'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import AllItineraries from '@/components/AllItineraries.vue'
import ItineraryPage from '@/components/ItineraryPage.vue'
import Chatbot from '@/components/Chatbot.vue'

export default defineComponent({
  name: 'App',
  components: {
    Footer,
    Header
  },
  methods: {
    resetForm () {
      (this.$refs.view as typeof ItineraryPage).resetForm()
    }
  }
})
